import {
  overview,
  plateNumber,
  customerId,
  mobileNumber,
  startDate,
  endDate,
  search,
  id,
  select,
  confirm,
  hours,
} from './common';

export const validateSessions = {
  overview,
  plateNumber,
  customerId,
  mobileNumber,
  startDate,
  endDate,
  search,
  table: {
    plateNumber,
    id,
    entryTime: 'وقت الدخول',
    parkingDuration: 'مدة ركن السيارة',
    mobileNumber,
    gracePeriod: 'فترة السماح',
    validateSession: 'اعتماد رسوم المواقف',
    validation: 'الاعتماد',
    customerId,
    hours,
    entireSession: 'الجلسة بأكملها',
  },
  modal: {
    selectTheValidationDuration: 'حدد مدة الاعتماد لرسوم المواقف ',
    validationDuration: 'مدة الاعتماد لرسوم السيارة',
    oneHoursValidation: 'ساعة واحدة للاعتماد (1 س)',
    twoHoursValidation: 'ساعتين للاعتماد (2 س)',
    threeHoursValidation: 'ثلاثة ساعات للاعتماد (3 س)',
    fourHoursValidation: 'أربعة ساعات للاعتماد (4 س)',
    entireSessionValidation: 'اعتماد المواقف بدون رسوم ',
    entireSessionValidationNote:
      'يتيح هذا الاختيار للمستخدم الحصول على اعتماد موقف السيارة ليوم كامل دون الحاجة إلى دفع الرسوم',
    select,
    confirmationMessage: 'رسالة تأكيد',
    confirmMessage: 'هل أنت متأكد أنك تريد إضافة ما يلي؟',
    duration: 'مدة',
    plateNumber,
    confirm,
  },
  messages: {
    validated: 'تم الاعتماد لرسوم المواقف بنجاح!',
  },
  validation: {
    requiredPlateNumber: 'الرجاء إدخال رقم لوحة السيارة!',
    requiredCustomerId: 'الرجاء إدخال رمز التعريف للعميل!',
    requiredMobileNumber: 'الرجاء إدخال رقم الجوال!',
    requiredDateTime: 'الرجاء إدخال الوقت والتاريخ!',
  },
};
