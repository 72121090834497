import React, { useCallback, useState } from 'react';
import { Content } from 'components/Content';
import { Divider } from 'antd';
import { ValidateSessionSearchForm } from './components/ValidateSessionSearchForm';
import { ValidateSessionTable } from './components/ValidateSessionTable';
import { ParkingSessionModel } from 'models/parkingSessionModel';
import parkingSessionService from 'services/parkingSession.service';
import { SearchFilterValues } from 'features/ValidateSessions/components/ValidateSessionSearchForm/types';
import { defaultPagination, PaginationModel } from 'models/paginationModel';
import { useTranslation } from 'react-i18next';

import './ValidateSessions.scss';

export const ValidateSessions = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState<ParkingSessionModel[]>();
  const [pagination, setPagination] = useState<PaginationModel>(defaultPagination);
  const [searchParams, setSearchParams] = useState<SearchFilterValues>({});

  const loadData = useCallback((params: SearchFilterValues & { page?: number; limit?: number }) => {
    setLoading(true);
    parkingSessionService
      .getParkingSession({
        page: 1,
        limit: 20,
        ...params,
      })
      .then(({ data: { items, meta } }) => {
        setModels(items);
        setPagination(meta);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onChangeSearchFormHandler = useCallback((params: SearchFilterValues) => {
    setSearchParams(params);
    loadData(params);
  }, []);

  const onChangePaginationHandler = useCallback(
    (page: number, pageSize: number) => {
      loadData({ ...searchParams, page, limit: pageSize });
    },
    [searchParams],
  );

  return (
    <Content title={t('validateSessions.overview')} className="validate-session-overview">
      <ValidateSessionSearchForm onChange={onChangeSearchFormHandler} />
      {(loading || models) && (
        <>
          <Divider />
          <ValidateSessionTable
            data={models || []}
            loading={loading}
            setData={setModels}
            pagination={pagination}
            onChangePagination={onChangePaginationHandler}
          />
        </>
      )}
    </Content>
  );
};
