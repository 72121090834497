import { UserModel } from 'models/user.model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TenantUsersSearchForm } from '../TenantUsersSearchForm';
import { TenantUsersTable } from '../TenantUsersTable';
import { usersService } from 'services/users.service';
import { SearchFilterValues } from 'features/Users/components/TenantUsers/types';
import { message, Modal } from 'antd';
import { getColumns } from './columns';
import { useNavigate } from 'react-router-dom';
import { USERS_TENANT_USERS_EDIT_TENANT_USER_URL } from 'utils/constants/clientUrls';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { getInitialValuesForSearchForm } from 'utils/form/initialValues';
import { useFacilities, useTenants } from 'hooks';
import { useTranslation } from 'react-i18next';

import './Overview.scss';

export const Overview = () => {
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);
  const tenants = useTenants();
  const facilities = useFacilities();
  const [users, setUsers] = useState<UserModel[]>([]);
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    usersService.getTenantUsers().then(({ data }) => setUsers(data));
  }, []);

  const filterChangedHandler = useCallback((params: SearchFilterValues) => {
    usersService.getTenantUsers(params).then(({ data }) => setUsers(data));
  }, []);

  const onEditClickHandler = useCallback((id: number) => {
    navigate(USERS_TENANT_USERS_EDIT_TENANT_USER_URL.route.replace(':userId', String(id)));
  }, []);

  const onDeleteClickHandler = useCallback(
    (id: number) => {
      modal.confirm({
        title: t('tenantUsers.confirm.disable'),
        icon: <ExclamationCircleFilled />,
        onOk() {
          usersService
            .disableTenantUser(id)
            .then(() => {
              setUsers((models) => models.filter(({ user_id }) => user_id !== id));

              message.success(t('tenantUsers.message.deactivated'));
            })
            .catch((result) => {
              message.error(result.message);
            });
        },
        okText: t('tenantUsers.confirm.ok'),
        cancelText: t('tenantUsers.confirm.cancel'),
        className: 'confirm-modal',
        okButtonProps: {
          color: '#D50066',
        },
      });
    },
    [t],
  );

  const initialValues = useMemo(() => {
    return getInitialValuesForSearchForm(user, tenants, facilities);
  }, [user?.tenant_id, user?.account_type, facilities, tenants]);

  return (
    <div className="tenant-users-overview-content">
      {contextHolder}
      <TenantUsersSearchForm
        facilities={facilities}
        tenants={tenants}
        onFilterChanged={filterChangedHandler}
        initialValues={initialValues}
      />
      <TenantUsersTable
        users={users}
        columns={getColumns(t, onEditClickHandler, onDeleteClickHandler)}
      />
    </div>
  );
};
