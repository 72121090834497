import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAppDispatch } from 'hooks/useAppSelector';
import { getAxiosErrorMessage } from 'lib/api/getAxiosErrorMessage';
import { Link } from 'react-router-dom';
import { authService } from 'services/auth.service';
import { authActions } from 'store/auth';
import { useTranslation } from 'react-i18next';

import './LoginForm.scss';

type FormValues = {
  login: string;
  password: string;
  remember?: boolean;
};

export const LoginForm = () => {
  const { t } = useTranslation();

  const [form] = useForm();
  const dispatch = useAppDispatch();

  const handleSubmit = async ({ login, password, remember }: FormValues) => {
    try {
      await authService.login({
        login,
        password,
        remember: !!remember,
      });
      dispatch(authActions.setIsLogged(true));
    } catch (e) {
      const errorMessage = getAxiosErrorMessage(e);
      if (errorMessage) message.error(errorMessage);
    }
  };

  return (
    <Form<FormValues> className="login-form" form={form} onFinish={handleSubmit}>
      <Form.Item name="login" rules={[{ required: true, message: t('validation.required') }]}>
        <Input prefix={<UserOutlined />} placeholder={t('login.loginPlaceholder')} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: t('validation.required') }]}>
        <Input.Password prefix={<LockOutlined />} placeholder={t('login.passwordPlaceholder')} />
      </Form.Item>
      <div className="checkbox-row">
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>{t('login.rememberMe')}</Checkbox>
        </Form.Item>
        <Link to={'/'}>{t('login.forgetPassword')}</Link>
      </div>

      <Button htmlType="submit" type="primary">
        {t('login.loginBtn')}
      </Button>
    </Form>
  );
};
