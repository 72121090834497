import React from 'react';
import { TableProps } from 'antd/lib';
import { UserModel } from 'models/user.model';
import { Space } from 'antd';
import editIcon from 'assets/images/edit-icon.svg';
import activateIcon from 'assets/images/activate-user-icon.svg';
import { IconButton } from 'components/Button/IconButton';
import { getColumns as columns } from '../TenantUsersTable/columns';
import { TFunction } from 'i18next';

type Props = {
  t: TFunction;
  onEditClick: (id: number) => void;
  onActivateClick: (id: number) => void;
};

export function getColumns(
  t: Props['t'],
  onEditClick: Props['onEditClick'],
  onActivateClick: Props['onActivateClick'],
): TableProps<UserModel>['columns'] {
  return [
    ...columns(t),
    {
      title: t('tenantUsers.table.action'),
      render(_, record) {
        return (
          <Space direction="horizontal" className="column-action-space">
            <IconButton icon={editIcon} onClick={() => onEditClick(record.user_id)} />
            <IconButton icon={activateIcon} onClick={() => onActivateClick(record.user_id)} />
          </Space>
        );
      },
    },
  ];
}
