import { hasRole } from 'utils/permission/role';
import { AccountType, UserModel } from 'models/user.model';
import { TenantModel } from 'models/tenant.model';
import { FacilityModel } from 'models/facility.model';

export function getInitialValuesForSearchForm(
  user: UserModel,
  tenants: TenantModel[],
  facilities: FacilityModel[],
) {
  if (hasRole(user?.account_type, [AccountType.FacilityOwner])) {
    const facility = facilities?.[0];

    return {
      facility_id: facility?.facility_id,
      facility_number: facility?.facility_number.toString(),
    };
  }

  if (!hasRole(user?.account_type, [AccountType.TenantAdmin])) {
    return undefined;
  }

  const result: { tenant_id?: number; facility_number?: string; facility_id?: number } = {
    tenant_id: user?.tenant_id,
  };

  const facility_id = tenants.find(({ tenant_id }) => tenant_id === user?.tenant_id)?.facility_id;

  if (facility_id) {
    result.facility_id = facility_id;

    result.facility_number = facilities
      .find((value) => facility_id === value.facility_id)
      ?.facility_number.toString();
  }

  return result;
}
