import React, { createRef, ReactNode, useCallback, useMemo } from 'react';
import { Select } from 'antd';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { numberToWords } from 'utils/string/numberToWords';
import { FullWidthButton } from 'components/Button/FullWidthButton';
import { StepItemProps } from '../../type';
import Icon from '@ant-design/icons';
import { ArrowDownIcon } from 'components/Icons';
import { Note } from 'components/Note';
import { useTranslation } from 'react-i18next';
import { hasRole } from 'utils/permission/role';
import { AccountType } from 'models/user.model';

import './SelectValidationDuration.scss';

const maxAllowedValidationHours = 4;

export function SelectValidationDuration({
  onStepChange,
  duration,
  onDurationChange,
}: StepItemProps) {
  const ref = createRef<HTMLDivElement>();
  const user = useAppSelector(getCurrentUser);
  const { t } = useTranslation();

  const items = useMemo(() => {
    const hasAdminPrivileges = hasRole(user?.account_type, [
      AccountType.TenantAdmin,
      AccountType.FacilityOwner,
      AccountType.Admin,
    ]);

    const result = Array.from<number, { key: number; label: string | ReactNode }>(
      {
        length: hasAdminPrivileges
          ? maxAllowedValidationHours
          : user.max_allowed_validation_hours || 0,
      },
      (value, index) => {
        const number = index + 1;

        return {
          key: number,
          label: t(`validateSessions.modal.${numberToWords(number) as 'one'}HoursValidation`),
        };
      },
    );

    if (hasAdminPrivileges || user?.allow_entire_session_validation) {
      result.push({
        label: (
          <div>
            {t('validateSessions.modal.entireSessionValidation')}
            <Note>{t('validateSessions.modal.entireSessionValidationNote')}</Note>
          </div>
        ),
        key: 0,
      });
    }

    return result;
  }, [user?.max_allowed_validation_hours, user?.allow_entire_session_validation, t]);

  const onSelectHandler = useCallback((value: string) => {
    onDurationChange(+value);
  }, []);

  return (
    <div className="select-validation-duration">
      <Select
        getPopupContainer={() => ref.current as HTMLDivElement}
        placeholder={t('validateSessions.modal.validationDuration')}
        onSelect={onSelectHandler}
        value={duration?.toString()}
        suffixIcon={<Icon component={ArrowDownIcon} />}
        dropdownAlign={{ offset: [0, 10] }}
      >
        {items?.map(({ key, label }) => <Select.Option key={key}>{label}</Select.Option>)}
      </Select>
      <div className="select-validation-duration-dropdown-container" ref={ref} />
      {Number.isInteger(duration) && (
        <FullWidthButton
          type="primary"
          className="select-validation-duration-button"
          onClick={onStepChange}
        >
          {t('validateSessions.modal.select')}
        </FullWidthButton>
      )}
    </div>
  );
}
