import { Columns } from 'components/DetailsGrid';
import { TenantDetailsModel } from 'models/tenant.model';
import { TFunction } from 'i18next';

export function getColumns(t: TFunction): Columns<TenantDetailsModel>[] {
  return [
    {
      index: 'available_hours',
      title: t('purchase.availableHours'),
      render(value, _, record) {
        return t('purchase.hours', {
          value: +record.total_purchased_hours - +record.total_validated_hours,
        });
      },
    },
    {
      index: 'total_purchased_hours',
      title: t('purchase.totalBoughtHours'),
      render(value) {
        return t('purchase.hours', { value: +value });
      },
    },
    {
      index: 'total_validated_hours',
      title: t('purchase.totalConsumedHours'),
      render(value) {
        return t('purchase.hours', { value: +value });
      },
    },
  ];
}
