import { TableProps } from 'antd/lib';
import { ParkingSessionModel } from 'models/parkingSessionModel';
import moment from 'moment-timezone';
import React from 'react';
import { ActionColumn } from './components/ActionColumn';
import { TFunction } from 'i18next';
import { datesDuration } from 'utils/date/datesDuration';

export const getColumns = (
  t: TFunction,
  onValidateClick: (entry: ParkingSessionModel) => void,
): TableProps<ParkingSessionModel>['columns'] => [
  {
    dataIndex: 'index',
    title: '#',
    width: '3%',
    render(value, entry, index) {
      return ('0' + (index + 1)).slice(-2);
    },
  },
  {
    dataIndex: 'plate_number',
    title: t('validateSessions.table.plateNumber'),
  },
  {
    dataIndex: 'customer_id',
    title: t('validateSessions.table.customerId'),
  },
  {
    dataIndex: 'entry_date_time',
    title: t('validateSessions.table.entryTime'),
    render(value) {
      if (!value) {
        return;
      }
      return moment(value).local().format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    dataIndex: 'parking_duration',
    title: t('validateSessions.table.parkingDuration'),
    render(_, { entry_date_time }) {
      return datesDuration(moment(), moment(entry_date_time).local());
    },
  },
  {
    dataIndex: 'customer_mobile',
    title: t('validateSessions.table.mobileNumber'),
  },
  {
    dataIndex: 'entry_grace_period',
    title: t('validateSessions.table.gracePeriod'),
    render(value) {
      if (!value) {
        return;
      }
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    dataIndex: 'actions',
    title: t('validateSessions.table.validateSession'),
    fixed: 'right',
    render: (_, model) => React.createElement(ActionColumn, { onClick: onValidateClick, model }),
  },
];
