import React, { useCallback } from 'react';
import { paymentMethods } from './constants';
import { RateModel } from 'models/rate.model';
import { SelectModal } from 'components/SelectModal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PURCHASE_PAYMENT_CHECKOUT_URL } from 'utils/constants/clientUrls';
import { PaymentMethodType } from 'utils/enums/paymentMethods';
import { TenantDetailsModel } from 'models/tenant.model';

type PurchaseModalProps = {
  open: boolean;
  onCancel: () => void;
  rate: RateModel;
  hours: number;
  tenant: TenantDetailsModel;
};

export function PurchaseModal({ open, onCancel, rate, tenant, hours }: PurchaseModalProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSelectPaymentMethodHandler = useCallback(
    (method: PaymentMethodType) => {
      navigate(PURCHASE_PAYMENT_CHECKOUT_URL.path({ type: method }), {
        state: {
          tenant,
          rate,
          hours,
        },
      });
    },
    [rate, hours],
  );

  return (
    <SelectModal
      title={t('purchase.modal.selectPaymentMethod')}
      open={open}
      onCancel={onCancel}
      list={paymentMethods}
      onSelect={onSelectPaymentMethodHandler}
    />
  );
}
