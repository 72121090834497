import React from 'react';
import { PaymentMethodType } from 'utils/enums/paymentMethods';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { CardPayment } from 'features/Purchase/components/Checkout/components/CardPayment';
import { RateModel } from 'models/rate.model';
import { TenantDetailsModel } from 'models/tenant.model';
import { BankTransfer } from 'features/Purchase/components/Checkout/components/BankTransfer';

type PaymentTypeFactoryProps = {
  tenant: TenantDetailsModel;
  type?: string;
  rate: RateModel;
  hours: number;
  onPurchase: () => void;
};

export function PaymentTypeFactory({
  tenant,
  type,
  rate,
  hours,
  onPurchase,
}: PaymentTypeFactoryProps) {
  const { t } = useTranslation();

  switch (type) {
    case PaymentMethodType.BankTransfer:
      return <BankTransfer rate={rate} hours={hours} tenant={tenant} />;
    case PaymentMethodType.VisaPayment:
      return <CardPayment rate={rate} hours={hours} onConfirm={onPurchase} tenant={tenant} />;
    default:
      return <Result status="404" title="404" subTitle={t('page.notFoundText')} />;
  }
}
