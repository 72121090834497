import React, { useEffect } from 'react';
import { MailOutlined, PhoneOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FacilityModel } from 'models/facility.model';
import { UserModel } from 'models/user.model';
import { usersService } from 'services/users.service';
import { TenantModel } from 'models/tenant.model';
import { useTranslation } from 'react-i18next';

import './TenantAdminsSerachForm.scss';

type Props = {
  facilities: FacilityModel[];
  tenants: TenantModel[];
  setUsers: (users: UserModel[]) => void;
  initialValues?: FormValues;
};

export type FormValues = {
  facility_number?: number;
  email?: string;
  mobile?: string;
  tenant_id?: string;
};

export const TenantAdminsSearchForm = ({ facilities, setUsers, tenants, initialValues }: Props) => {
  const { t } = useTranslation();
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleSubmit = ({ email, facility_number, mobile, tenant_id }: FormValues) => {
    const params: FormValues = {};

    if (email?.trim()) params.email = email.trim();
    if (facility_number) params.facility_number = facility_number;
    if (mobile) params.mobile = mobile;
    if (tenant_id) params.tenant_id = tenant_id;

    usersService.getTenantAdmins(params).then(({ data }) => setUsers(data));
  };

  return (
    <Form<FormValues>
      className="tenant-users-search-form"
      form={form}
      layout="inline"
      onFinish={handleSubmit}
    >
      <Form.Item name="facility_number">
        <Select
          placeholder={
            <>
              <ShopOutlined /> {t('tenantAdmins.search.facility')}
            </>
          }
          allowClear
        >
          {facilities.map(({ facility_name, facility_number }) => (
            <Select.Option key={facility_number} value={facility_number.toString()}>
              {facility_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="tenant_id">
        <Select
          placeholder={
            <>
              <TeamOutlined /> {t('tenantAdmins.search.tenant')}
            </>
          }
          allowClear
        >
          {tenants.map(({ tenant_id, tenant_name }) => (
            <Select.Option key={tenant_id} value={tenant_id}>
              {tenant_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="email">
        <Input prefix={<MailOutlined />} placeholder={t('tenantAdmins.search.email')} allowClear />
      </Form.Item>
      <Form.Item name="mobile">
        <Input
          prefix={<PhoneOutlined />}
          placeholder={t('tenantAdmins.search.mobileNumber')}
          allowClear
        />
      </Form.Item>

      <Button className="submit-button" htmlType="submit" type="primary">
        {t('tenantAdmins.search.button')}
      </Button>

      <Divider />
    </Form>
  );
};
