import React, { useCallback, useState } from 'react';
import { Section } from 'components/Section';
import { DetailsGrid } from 'components/DetailsGrid';
import { PurchaseModal } from 'features/Purchase/components/PurchaseModal';
import { getColumns } from './config';
import { Button } from 'antd';
import { RateModel } from 'models/rate.model';
import { Note } from 'components/Note';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/SelectModal/hooks/useModal';
import { TenantDetailsModel } from 'models/tenant.model';

type Props = {
  rate: RateModel;
  tenant: TenantDetailsModel;
};

export function PurchaseHoursSection({ rate, tenant }: Props) {
  const { t } = useTranslation();
  const [hours, setHours] = useState<number | null>(null);
  const [open, openHandler, closeHandler] = useModal();

  const onChangeHandler = useCallback((value: number | null) => {
    if (!value) {
      setHours(null);
      return;
    }

    if (value > 0 && value % 1 === 0) {
      setHours(value);
    }
  }, []);

  const onPayNowClickHandler = useCallback(() => {
    openHandler();
  }, []);

  const hasHours = hours && hours > 0;

  return (
    <Section title={t('purchase.purchaseHours')} className="pre-paid-information-section">
      <DetailsGrid data={{ ...rate, value: hours }} columns={getColumns(t, onChangeHandler)} />
      <Note>
        {t('purchase.purchaseHoursNote', { rate: rate.rate, amount: 100, result: rate.rate * 100 })}
      </Note>

      <Button type="primary" disabled={!hasHours} onClick={onPayNowClickHandler}>
        {t('purchase.payNow')}
      </Button>

      {hasHours && (
        <PurchaseModal
          open={open}
          onCancel={closeHandler}
          hours={hours}
          rate={rate}
          tenant={tenant}
        />
      )}
    </Section>
  );
}
