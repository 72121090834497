import React from 'react';
import { TableProps } from 'antd/lib';
import { UserModel } from 'models/user.model';
import { Space } from 'antd';
import editIcon from 'assets/images/edit-icon.svg';
import deleteIcon from 'assets/images/delete-user-icon.svg';
import { IconButton } from 'components/Button/IconButton';
import { getColumns as columns } from '../TenantUsersTable/columns';
import { TFunction } from 'i18next';

type Arguments = {
  t: TFunction;
  onEditClick: (id: number) => void;
  onDeleteClick: (id: number) => void;
};

export function getColumns(
  t: Arguments['t'],
  onEditClick: Arguments['onEditClick'],
  onDeleteClick: Arguments['onDeleteClick'],
): TableProps<UserModel>['columns'] {
  return [
    ...columns(t),
    {
      title: t('tenantUsers.table.action'),
      fixed: 'right',
      render(_, record) {
        return (
          <Space direction="horizontal" className="column-action-space">
            <IconButton icon={editIcon} onClick={() => onEditClick(record.user_id)} />
            <IconButton icon={deleteIcon} onClick={() => onDeleteClick(record.user_id)} />
          </Space>
        );
      },
    },
  ];
}
