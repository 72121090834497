import React from 'react';

export function ArrowDownIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 12">
      <path
        fill="currentColor"
        d="M.454.94a1.5 1.5 0 0 1 2.12 0L10 8.363 17.425.94a1.5 1.5 0 0 1 2.12 2.121l-8.485 8.486a1.5 1.5 0 0 1-2.12 0L.453 3.06a1.5 1.5 0 0 1 0-2.12Z"
      />
    </svg>
  );
}
