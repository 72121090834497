import React from 'react';
import { Header } from 'components/Layout/Header';
import { SideBar } from 'components/Layout/SideBar';

import './Layout.scss';

type Props = {
  children?: React.ReactNode;
};

export const Layout = ({ children }: Props) => {
  return (
    <div className="layout">
      <SideBar />
      <div className="layout-main">
        <Header />
        <div className="layout-content">{children}</div>
      </div>
    </div>
  );
};
