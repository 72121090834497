import React from 'react';

export function NotificationIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 27 25">
      <path
        fill="currentColor"
        d="M12.014 25c-2.261 0-4.102-1.752-4.102-3.906 0-.431.368-.782.82-.782.453 0 .82.35.82.782 0 1.293 1.105 2.343 2.462 2.343 1.356 0 2.46-1.05 2.46-2.343 0-.431.368-.782.82-.782.454 0 .821.35.821.782 0 2.154-1.84 3.906-4.101 3.906Z"
      />
      <path
        fill="currentColor"
        d="M21.037 21.875H2.99c-1.055 0-1.914-.818-1.914-1.823 0-.533.244-1.038.67-1.385a.767.767 0 0 1 .087-.063c1.606-1.334 2.524-3.254 2.524-5.281v-2.906c0-4.021 3.436-7.292 7.657-7.292.175 0 .364.003.539.031.447.071.75.474.675.9-.075.424-.506.712-.944.64-.088-.013-.184-.008-.27-.008-3.316 0-6.016 2.57-6.016 5.729v2.906c0 2.517-1.158 4.898-3.175 6.532a.6.6 0 0 1-.048.036.25.25 0 0 0-.058.161c0 .142.125.26.273.26h18.047c.149 0 .274-.118.274-.26a.242.242 0 0 0-.06-.161l-.047-.036c-2.018-1.635-3.175-4.015-3.175-6.532v-1.135c0-.432.368-.782.82-.782.453 0 .821.35.821.782v1.135c0 2.028.92 3.949 2.528 5.284a.9.9 0 0 1 .084.06c.425.347.67.852.67 1.385 0 1.005-.86 1.823-1.915 1.823Z"
      />
      <path
        fill="currentColor"
        d="M19.67 10.417c-3.016 0-5.469-2.337-5.469-5.209C14.201 2.336 16.654 0 19.67 0c3.015 0 5.469 2.337 5.469 5.208 0 2.872-2.453 5.209-5.469 5.209Zm0-8.854c-2.111 0-3.828 1.635-3.828 3.645s1.717 3.646 3.828 3.646c2.11 0 3.828-1.635 3.828-3.646 0-2.01-1.717-3.646-3.828-3.646Z"
      />
    </svg>
  );
}
