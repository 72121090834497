import React from 'react';

import './TenantUsers.scss';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  children?: React.ReactNode;
};

export const TenantUsers = ({ children }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="tenant-users-content">
      <nav className="tenant-users-navbar">
        <Link
          className={`tenant-users-navbar-item ${location.pathname.includes('overview') && 'tenant-users-navbar-item--active'}`}
          to="/users/tenant_users/overview"
        >
          {t('tenantUsers.tabs.overview')}
        </Link>
        <Link
          className={`tenant-users-navbar-item ${location.pathname.includes('disabled_tenant_users') && 'tenant-users-navbar-item--active'}`}
          to="/users/tenant_users/disabled_tenant_users"
        >
          {t('tenantUsers.tabs.disabledTenantUsers')}
        </Link>
        <Link
          className={`tenant-users-navbar-item ${location.pathname.includes('add_tenant_user') && 'tenant-users-navbar-item--active'}`}
          to="/users/tenant_users/add_tenant_user"
        >
          {t('tenantUsers.tabs.addTenantUser')}
        </Link>
      </nav>

      {children}
    </div>
  );
};
