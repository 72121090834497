import React from 'react';

const VisaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 26">
      <path
        fill="currentColor"
        d="M4.25.5A3.77 3.77 0 0 0 .5 4.25v17.5c0 2.056 1.694 3.75 3.75 3.75h27.5a3.77 3.77 0 0 0 3.75-3.75V4.25A3.77 3.77 0 0 0 31.75.5H4.25Zm0 2.5h27.5c.706 0 1.25.544 1.25 1.25v17.5A1.23 1.23 0 0 1 31.75 23H4.25A1.23 1.23 0 0 1 3 21.75V4.25C3 3.544 3.544 3 4.25 3Zm18.008 5.469c-2.408 0-3.672 1.187-3.672 2.695 0 2.729 3.2 2.35 3.2 3.75 0 .241-.2.784-1.525.784s-2.187-.47-2.187-.47l-.388 1.8s.819.507 2.463.507c1.637 0 3.942-1.26 3.942-3.088 0-2.194-3.204-2.34-3.204-3.318 0-.5.425-.9 1.563-.9.742 0 1.563.587 1.563.587l.392-1.912s-1.088-.431-2.15-.431l.003-.004Zm-10.273.156-2.11 6.172s-.105-.535-.156-.86c-1.194-2.675-3.125-3.672-3.125-3.672l1.837 6.992h2.5l3.513-8.632h-2.459Zm3.359 0-1.368 8.633h2.308l1.366-8.633h-2.306Zm12.422 0-3.75 8.633h2.265l.469-1.21h2.891l.234 1.21h2.07l-1.758-8.633h-2.42Zm-23.712.037S8.55 10.044 9.6 13.43l-.779-3.906s-.343-.863-1.25-.863H4.056l-.001.002Zm24.571 2.463.663 3.242h-1.875l1.212-3.242Z"
      />
    </svg>
  );
};

export default VisaIcon;
