import React, { ChangeEventHandler, useState } from 'react';
import { RateModel } from 'models/rate.model';
import { Input, Space } from 'antd';
import { formatCreditCardNumber } from 'utils/string/formatCreditCardNumber';
import { useTranslation } from 'react-i18next';
import { CheckoutCard } from '../CheckoutCard';
import { TenantDetailsModel } from 'models/tenant.model';

import './CardPayment.scss';
import { FullWidthButton } from 'components/Button/FullWidthButton';

type CardPaymentProps = {
  tenant: TenantDetailsModel;
  rate: RateModel;
  hours: number;
  tax?: number;
  onConfirm: () => void;
};

export function CardPayment({ tenant, rate, hours, onConfirm, tax = 15 }: CardPaymentProps) {
  const { t } = useTranslation();
  const [cardDetails, setCardDetails] = useState<{ number: string; month: string; year: string }>({
    month: '',
    number: '',
    year: '',
  });

  const changeHandler: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    if (target.value === '' || /^[0-9\b| ]+$/.test(target.value)) {
      setCardDetails((prevState) => ({
        ...prevState,
        [target.name]:
          target.name === 'number' ? formatCreditCardNumber(target.value) : target.value,
      }));
    }

    return false;
  };

  const onFinishHandler = () => {
    // TODO: refactor it
    if (cardDetails.number.length < 19) {
      return;
    }

    if (!cardDetails.number) {
      return;
    }

    if (!cardDetails.year) {
      return;
    }

    onConfirm();
  };

  return (
    <CheckoutCard
      tenant={tenant}
      paymentTitle={t('cardPayment.enterCardDetails')}
      rate={rate}
      hours={hours}
      tax={tax}
      footer={
        <FullWidthButton type="primary" className="confirm-button" onClick={onFinishHandler}>
          {t('cardPayment.confirmPayment')}
        </FullWidthButton>
      }
    >
      <div className="card-payment">
        <section className="card-payment-section">
          <h5 className="card-payment-section-title">{t('cardPayment.cardNumber')}</h5>
          <Input
            type="tel"
            name="number"
            maxLength={19}
            onChange={changeHandler}
            value={cardDetails.number}
            placeholder="3700 0000 0000 002"
          />
        </section>

        <section className="card-payment-section">
          <h5 className="card-payment-section-title">{t('cardPayment.expiryDate')}</h5>
          <Space className="expire-date">
            <div className="expire-date-month">
              <label form="month">{t('cardPayment.month')}</label>
              <Input
                id="month"
                type="text"
                name="month"
                pattern="(0[1-9]|1[0-2])"
                maxLength={2}
                placeholder="07"
                value={cardDetails.month}
                onChange={changeHandler}
              />
            </div>
            <div className="expire-date-divider">/</div>
            <div className="expire-date-year">
              <label form="year">{t('cardPayment.year')}</label>
              <Input
                id="year"
                type="text"
                name="year"
                maxLength={2}
                placeholder="24"
                value={cardDetails.year}
                onChange={changeHandler}
              />
            </div>
          </Space>
        </section>
      </div>
    </CheckoutCard>
  );
}
