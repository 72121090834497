import React from 'react';

export function ArIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
      <g clipPath="url(#ar-icon)">
        <path
          fill="currentColor"
          d="M15 5c-2.4 0-4.375 1.975-4.375 4.375 0 1.162.475 2.225 1.25 3.012a6.864 6.864 0 0 0-3.125 5.738c0 3.788 3.088 6.875 6.875 6.875 2.2 0 4.375-.575 6.25-1.675l-1.25-2.162a10.054 10.054 0 0 1-5 1.337 4.356 4.356 0 0 1-4.375-4.375 4.363 4.363 0 0 1 3.238-4.225L21 12.15l-.65-2.412-5.563 1.512c-.937-.125-1.662-.9-1.662-1.875 0-1.05.825-1.875 1.875-1.875.325 0 .625.088.938.25l1.25-2.162A4.348 4.348 0 0 0 15 5Z"
        />
        <rect
          width="27.5"
          height="27.5"
          x="1.25"
          y="1.25"
          stroke="currentColor"
          strokeWidth="2.5"
          rx="3.75"
        />
      </g>
      <defs>
        <clipPath id="ar-icon">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
