import React, { useCallback, useRef, useState } from 'react';
import { Button, Modal, Space } from 'antd';
import { reportTypes } from './config';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReportModel } from 'models/report.model';
import { getColumns } from 'features/Reports/components/ReportsTable/columns';
import { ColumnType } from 'antd/lib/table/interface';
import { ReportType } from 'utils/enums/reportType';
import { loadReportsData } from 'features/Reports/components/ReportFileTypeModal/loader';
import { generateTableReport as generatePdfReport } from 'utils/export/pdf';
import { generateTableReport as generateExcelReport } from 'utils/export/excel';

import './ReportFileTypeModal.scss';

type ReportFileTypeModalProps = {
  open?: boolean;
  setOpen: (open: boolean) => void;
  searchParams?: object;
};

export function ReportFileTypeModal({
  open,
  setOpen,
  searchParams = {},
}: ReportFileTypeModalProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [type, setType] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const onCancel = () => {
    setOpen(false);
    setType(undefined);
    setLoading(false);
  };

  const onConfirmHandler = useCallback(async () => {
    setLoading(true);

    const columns = getColumns(t) as ColumnType<ReportModel>[];
    const data: ReportModel[] = await loadReportsData(searchParams);

    switch (type) {
      case ReportType.Pdf:
        generatePdfReport<ReportModel>(columns, data);
        break;
      case ReportType.Excel:
        generateExcelReport<ReportModel>(columns, data);
        break;
    }

    onCancel();
  }, [type, searchParams, ref.current]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={false}
      destroyOnClose
      width="100%"
      rootClassName="custom-modal report-file-type-modal"
      title={t('report.exportModal.title')}
    >
      <div className="custom-modal-content">
        <Space className="report-file-type-modal-types-wrapper">
          {reportTypes.map(({ key, icon: Icon }) => (
            <Button
              key={key}
              icon={<Icon />}
              classNames={{ icon: `icon-${key}` }}
              className={classNames({ 'report-types-button': true, active: key === type })}
              onClick={() => setType(key)}
            >
              {t(`report.exportModal.${key}`)}
            </Button>
          ))}
        </Space>
        <Button
          type="primary"
          className="report-file-type-modal-confirm"
          disabled={!type}
          loading={loading}
          onClick={onConfirmHandler}
        >
          {t('report.exportModal.confirm')}
        </Button>
      </div>
    </Modal>
  );
}
