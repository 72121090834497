import { header } from './header';
import { sidebar } from './sidebar';
import { tenantAdmins } from './tenantAdmins';
import { tenantUsers } from './tenantUsers';
import { purchase } from './purchase';
import { currency } from './currency';
import { language } from './language';
import { validateSessions } from './validateSessions';
import { table } from './table';
import { login } from './login';
import { validation } from './validation';
import { report } from './report';
import { balanceHistory } from './balanceHistory';
import { page } from './page';
import { cardPayment } from './cardPayment';
import { bankTransfer } from './bankTransfer';

export default {
  translation: {
    header,
    sidebar,
    tenantAdmins,
    tenantUsers,
    purchase,
    currency,
    language,
    validateSessions,
    table,
    login,
    validation,
    report,
    page,
    balanceHistory,
    cardPayment,
    bankTransfer,
  },
};
