import React from 'react';
import { Logo } from 'components/Logo';
import { LoginForm } from 'features/Login/components/LoginForm';
import { useTranslation } from 'react-i18next';

import './Login.scss';
import VisualDataPng from 'assets/images/visual-data.png';

export const Login = () => {
  const { t } = useTranslation();

  return (
    <div className="login-container">
      <div className="login-info">
        <img src={VisualDataPng} alt="Men with virtual displays" />
        <h1>{t('login.greeting')}</h1>
        <p>{t('login.description')}</p>
      </div>
      <div className="login-form-container">
        <Logo />
        <p>{t('login.loginBy')}</p>
        <LoginForm />
      </div>
    </div>
  );
};
