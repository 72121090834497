import React from 'react';
import { Table } from 'antd';
import { getColumns } from './config';
import { useTranslation } from 'react-i18next';
import { BalanceHistoryModel } from 'models/balanceHistory.model';
import { getItemRender } from 'components/Table/helper';
import { PaginationModel } from 'models/paginationModel';

type BalanceHistoryTableProps = {
  loading: boolean;
  data: {
    pagination: PaginationModel;
    data?: BalanceHistoryModel[];
  };
  onChangePagination: (page: number, pageSize: number) => void;
};

export function BalanceHistoryTable({
  loading,
  data: { data, pagination },
  onChangePagination,
}: BalanceHistoryTableProps) {
  const { t } = useTranslation();

  return (
    <Table<BalanceHistoryModel>
      rowKey="date"
      columns={getColumns(t)}
      loading={loading}
      dataSource={data}
      rowClassName={(record: BalanceHistoryModel) => (record.archived ? 'archived-row' : '')}
      pagination={{
        total: pagination.totalItems,
        pageSize: pagination.itemsPerPage,
        current: pagination.currentPage,
        itemRender: getItemRender(t, pagination),
        onChange: onChangePagination,
        showSizeChanger: false,
      }}
    />
  );
}
