import React from 'react';
import validIcon from 'assets/images/valid-field.svg';

import './ValidIcon.scss';

export function ValidIcon() {
  return (
    <div className="valid-icon-container">
      <img src={validIcon} alt="Valid Field Icon" />
    </div>
  );
}
