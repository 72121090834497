import { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
  token: { colorPrimary: '#D50066', fontFamily: 'Brown, serif' },
  components: {
    Button: {
      borderRadius: 15,
      colorBgContainer: '#F5F7FA',
      colorText: '#333333',
      colorBorder: 'transparent !important',
      controlHeight: 43,
      fontSize: 20,
      fontWeight: 700,
    },
    Input: {
      colorBgContainer: '#F5F7FA',
      borderRadius: 15,
      colorText: '#858585',
      colorBorder: 'transparent !important',
      controlHeight: 39,
      fontSize: 18,
      paddingBlock: 0,
    },
    Select: {
      borderRadius: 15,
      colorBgContainer: '#F5F6FA',
      colorBorder: 'transparent !important',
      controlHeight: 41,
      fontSize: 18,
    },
    DatePicker: {
      borderRadius: 15,
      colorBgContainer: '#F5F6FA',
      colorBorder: 'transparent !important',
      controlHeight: 41,
      fontSize: 18,
    },
    Table: {
      cellFontSize: 16,
    },
    Modal: {
      titleFontSize: 20,
    },
  },
};
