export const overview = 'Overview';
export const tenant = 'Tenant';
export const facility = 'Facility';
export const mobileNumber = 'Mobile number';
export const email = 'Email';
export const search = 'Search';
export const userEmail = 'User Email';
export const userName = 'User Name';
export const tenantName = 'Tenant Name';
export const facilityName = 'Facility Name';
export const action = 'Action';
export const date = 'Date';
export const user = 'User';
export const plateNumber = 'Plate Number';
export const customerId = 'Customer ID';
export const startDate = 'Start Date';
export const endDate = 'End Date';
export const id = 'ID';
export const next = 'Next';
export const prev = 'Prev';
export const select = 'Select';
export const confirm = 'Confirm';
export const hours = '{{value}} Hours';
export const exportLabel = 'Export';
export const type = 'Type';
export const purchaseHours = 'Purchase Hours';
export const currentBalance = 'Current Balance';
