import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Layout, PaymentTypeFactory } from './components';
import { useTranslation } from 'react-i18next';
import { PaymentMethodType } from 'utils/enums/paymentMethods';
import { tenantsService } from 'services/tenants.service';
import { message } from 'antd';
import { PURCHASE_OVERVIEW_URL } from 'utils/constants/clientUrls';

import './Checkout.scss';

export function Checkout() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { type } = useParams();
  const {
    state: { rate, hours, tenant },
  } = useLocation();

  if (!tenant || !hours || !rate) {
    window.history.back();

    return <></>;
  }

  const onPurchaseHandler = () => {
    tenantsService
      .purchaseHours(tenant.id, {
        hours,
        paid_amount: rate.rate * hours,
      })
      .then(() => {
        navigate(PURCHASE_OVERVIEW_URL.route, { replace: true });
        message.success(t('purchase.message.successPayment'));
      })
      .catch((response) => {
        message.error(response.message);
      });
  };

  return (
    <Layout title={t(`checkout.title.${type as PaymentMethodType}`)}>
      <PaymentTypeFactory
        tenant={tenant}
        type={type}
        rate={rate}
        hours={hours}
        onPurchase={onPurchaseHandler}
      />
    </Layout>
  );
}
