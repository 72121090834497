import { FC } from 'react';
import { TFunction } from 'i18next';
import { MenuProps } from 'antd';
import { LanguageEnum } from 'utils/enums/language';
import { EnIcon, ArIcon } from 'components/Icons';

export enum SettingsMenuItemsEnum {
  changeLanguage = 'change-language',
}

export function getSettingsMenuItems(t: TFunction): MenuProps['items'] {
  return [
    {
      key: SettingsMenuItemsEnum.changeLanguage,
      label: t('header.changeLanguage'),
    },
  ];
}

export function getLanguages(t: TFunction): { key: LanguageEnum; name: string; icon: FC }[] {
  return [
    {
      key: LanguageEnum.English,
      name: t('language.en'),
      icon: EnIcon,
    },
    {
      key: LanguageEnum.Arabic,
      name: t('language.ar'),
      icon: ArIcon,
    },
  ];
}
