import { useAppDispatch } from 'hooks/useAppSelector';
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import { authService } from 'services/auth.service';
import { authActions } from 'store/auth';

export const Router = () => {
  const dispatch = useAppDispatch();
  const token = authService.getAccessToken();

  useEffect(() => {
    if (token) {
      dispatch(authActions.setIsLogged(true));
    } else {
      dispatch(authActions.setIsLogged(false));
    }
  }, []);

  return <RouterProvider router={router} />;
};
