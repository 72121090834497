import React, { useCallback } from 'react';
import { UserIcon } from 'components/UserIcon';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/useAppSelector';
import { authService } from 'services/auth.service';
import { authActions } from 'store/auth';
import { clearUserAction } from 'store/user/actions';
import { getCurrentUser } from 'store/user/selectors';
import { useTranslation } from 'react-i18next';
import { Settings } from './components/Settings';
import { LogoutIcon, Icon, NotificationIcon } from 'components/Icons';

import './Header.scss';

export const Header = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);

  const handleLogout = useCallback(() => {
    dispatch(authActions.setIsLogged(false));
    dispatch(clearUserAction());
    authService.logout();
  }, [dispatch]);

  return (
    <header className="header">
      <div className="user-row">
        <UserIcon />
        <span className="username">{t('header.greeting', { name: user?.display_name })}</span>
      </div>

      <div className="actions-row">
        <Settings />
        <Button type="default" shape="circle" icon={<Icon component={NotificationIcon} />} />
        <Button
          type="default"
          shape="circle"
          icon={<Icon component={LogoutIcon} />}
          onClick={handleLogout}
        />
      </div>
    </header>
  );
};
