import React, { useMemo } from 'react';
import { Button, Col, Row, Space } from 'antd';
import { StepItemProps } from '../../type';
import { numberToWords } from 'utils/string/numberToWords';
import { useTranslation } from 'react-i18next';

import './ConfirmMessage.scss';

export function ConfirmationMessage({ duration, entry, onStepChange }: StepItemProps) {
  const { t } = useTranslation();

  const durationName = useMemo(() => {
    if (!duration) {
      return t('validateSessions.modal.entireSessionValidation');
    }

    const result = t(`validateSessions.modal.${numberToWords(duration) as 'one'}HoursValidation`);

    return result.charAt(0).toUpperCase() + result.slice(1);
  }, [t]);

  return (
    <div className="confirm-message-step">
      <Space direction="vertical">
        <p>{t('validateSessions.modal.confirmMessage')}</p>
        <Row>
          <Col span={8}>{t('validateSessions.modal.duration')}:</Col>
          <Col>{durationName}</Col>
        </Row>
        <Row>
          <Col span={8}>{t('validateSessions.modal.plateNumber')}:</Col>
          <Col>{entry.plate_number}</Col>
        </Row>
      </Space>
      <Button type="primary" onClick={onStepChange}>
        Confirm
      </Button>
    </div>
  );
}
