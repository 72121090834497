import {
  date,
  userName,
  type,
  startDate,
  endDate,
  purchaseHours,
  currentBalance,
  search,
  facility,
  tenant,
  plateNumber,
} from './common';
import { ValidationType } from 'models/tenant.model';
import { BalanceHistoryType } from 'utils/enums/balanceHistoryType';

export const balanceHistory = {
  [ValidationType.PrePaid]: 'تاريخ مسبقة الدفع',
  [ValidationType.PostPaid]: 'Post-paid History',
  table: {
    date,
    userName,
    plateNumber,
    type,
    hours: 'الساعات',
    newValue: 'New Value',
    currentBalance,
  },
  form: {
    startDate,
    endDate,
    userName,
    type,
    purchaseHours,
    currentBalance,
    search,
    facility,
    tenant,
  },
  type: {
    [BalanceHistoryType.Purchase]: 'المشتريات',
    [BalanceHistoryType.Validation]: 'Validation',
  },
};
