import React from 'react';
import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';

import './FullWidthButton.scss';

type FullWidthButtonProps = ButtonProps;

export function FullWidthButton({ children, className, ...props }: FullWidthButtonProps) {
  return (
    <Button {...props} className={classNames('full-width-button', className)}>
      {children}
    </Button>
  );
}
