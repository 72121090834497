import { RuleObject, RuleRender, StoreValue } from 'rc-field-form/lib/interface';
import { TFunction } from 'i18next';

export const confirmRule =
  (message: string): RuleRender =>
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(message));
    },
  });

export const passwordRule =
  (min: number, t: TFunction): RuleRender =>
  () => ({
    validator(_: RuleObject, value: StoreValue) {
      if (!value) {
        return Promise.resolve();
      }

      const exp = new RegExp(`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!.* ).{${min},}$`);

      if (value.match(exp)) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(t('tenantUsers.validation.containPassword', { min })));
    },
  });

export const nameRule =
  (t: TFunction): RuleRender =>
  () => ({
    validator(_: RuleObject, value: StoreValue = '') {
      const exp = new RegExp('^[a-zA-Z]+ [a-zA-Z]+$');

      if (value.match(exp)) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(t('tenantUsers.validation.firstNameLastName')));
    },
  });
