import React, { ReactNode } from 'react';
import { Col, Row } from 'antd';

import './DetailsGrid.scss';
import classNames from 'classnames';

export type Columns<T> = {
  index: string;
  title: React.ReactNode;
  render?: (value: T[keyof T], index: string, record: T) => React.ReactNode;
};

type Props<T> = {
  columns: Columns<T>[];
  data?: T;
  className?: string;
};

export function DetailsGrid<T>({ columns, data, className }: Props<T>) {
  const span = 24 / columns.length;

  return (
    <div className={classNames('details-grid', className)}>
      <Row className="details-grid-header">
        {columns.map(({ index, title }) => (
          <Col key={index.toString()} span={span} className="details-grid-col">
            {title}
          </Col>
        ))}
      </Row>
      {data && (
        <Row className="details-grid-footer">
          {columns.map(({ index, render }) => (
            <Col key={index.toString()} span={span} className="details-grid-col">
              {render
                ? render(data[index as keyof T], index, data)
                : (data[index as keyof T] as ReactNode)}
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}
