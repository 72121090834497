import React from 'react';
import { TenantDetailsSection } from '../TenantDetailsSection';
import { PostPaidInformationSection } from './components/PostPaidInformationSection';
import { TenantDetailsModel } from 'models/tenant.model';
import { RateModel } from 'models/rate.model';
import { TenantPurchaseHistorySection } from '../TenantPurchaseHistorySection';
import { PurchaseHistoryModel } from 'models/purchaseHistory.model';

type PostPaidProps = {
  tenant: TenantDetailsModel;
  rate: RateModel;
  purchaseHours: PurchaseHistoryModel[];
};

export function PostPaid({ tenant, rate, purchaseHours }: PostPaidProps) {
  return (
    <div className="pre-paid-overview">
      <TenantDetailsSection model={tenant} />
      <PostPaidInformationSection tenant={tenant} rate={rate} />
      <TenantPurchaseHistorySection data={purchaseHours} />
    </div>
  );
}
