import { TenantModel } from 'models/tenant.model';

export const GUTTER = 105;

export type FormValues = {
  email: string;
  password: string;
  password_confirm: string;
  display_name: string;
  phone: string;
  mobile_phone_code: string;
  mobile_phone: string;
  description: string;
  contact_person?: string;
  tenant_id: number;
  max_allowed_validation_hours: number;
  allow_entire_session_validation?: boolean;
};

export type FacilitiesGroup = {
  facility_id: number;
  facility_name: string;
  tenants: TenantModel[];
};
