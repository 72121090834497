import { TableProps } from 'antd/lib';
import { UserModel } from 'models/user.model';
import { TFunction } from 'i18next';

export function getColumns(t: TFunction): TableProps<UserModel>['columns'] {
  return [
    {
      dataIndex: 'index',
      title: '#',
      width: '3%',
    },
    {
      dataIndex: 'email',
      title: t('tenantAdmins.table.userEmail'),
    },
    {
      dataIndex: 'display_name',
      title: t('tenantAdmins.table.userName'),
    },
    {
      dataIndex: 'facility',
      title: t('tenantAdmins.table.facility'),
    },
    {
      dataIndex: 'tenant_name',
      title: t('tenantAdmins.table.tenantName'),
    },
    {
      dataIndex: 'mobile',
      title: t('tenantAdmins.table.mobileNumber'),
    },
  ];
}
