import { useEffect, useState } from 'react';
import { FacilityModel } from 'models/facility.model';
import { facilitiesService } from 'services/facility.service';

export function useFacilities() {
  const [facilities, setFacilities] = useState<FacilityModel[]>([]);

  useEffect(() => {
    facilitiesService.getFacilities().then(({ data }) => setFacilities(data));
  }, []);

  return facilities;
}
