import React from 'react';
import { TenantDetailsSection } from '../TenantDetailsSection';
import { PurchaseHoursSection } from './components/PurchaseHoursSection';
import { PrePaidInformationSection } from './components/PrePaidInformationSection';
import { AccountType } from 'models/user.model';
import { Guard } from 'components/Guard/Guard';
import { TenantDetailsModel } from 'models/tenant.model';
import { RateModel } from 'models/rate.model';
import { TenantPurchaseHistorySection } from '../TenantPurchaseHistorySection';
import { PurchaseHistoryModel } from 'models/purchaseHistory.model';

type PrePaidProps = {
  tenant: TenantDetailsModel;
  rate: RateModel;
  purchaseHours: PurchaseHistoryModel[];
};

export function PrePaid({ tenant, rate, purchaseHours }: PrePaidProps) {
  return (
    <div className="pre-paid-overview">
      <TenantDetailsSection model={tenant} />
      <PrePaidInformationSection model={tenant} />
      <Guard roles={AccountType.TenantAdmin}>
        <PurchaseHoursSection rate={rate} tenant={tenant} />
      </Guard>
      <TenantPurchaseHistorySection data={purchaseHours} />
    </div>
  );
}
