import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from 'models/user.model';

interface InitState {
  user: UserModel;
}

export const { reducer: data, actions: userActions } = createSlice({
  name: 'user',
  initialState: {} as InitState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { reducer: isLoading, actions: userIsLoadingActions } = createSlice({
  name: 'user/isLoading',
  initialState: false,
  reducers: {
    setIsLoading: (_, action: PayloadAction<boolean>) => action.payload,
  },
});

export const user = combineReducers({
  data,
  isLoading,
});
