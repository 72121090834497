import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FacilityModel } from 'models/facility.model';
import { facilitiesService } from 'services/facility.service';
import { usersService } from 'services/users.service';
import { TenantUsersSearchForm } from '../TenantUsersSearchForm';
import { TenantModel } from 'models/tenant.model';
import { tenantsService } from 'services/tenants.service';
import { SearchFilterValues } from 'features/Users/components/TenantUsers/types';
import { UserModel } from 'models/user.model';
import { TenantUsersTable } from '../TenantUsersTable';
import { getColumns } from './columns';
import { useNavigate } from 'react-router-dom';
import { message, Modal } from 'antd';
import { USERS_TENANT_USERS_EDIT_TENANT_USER_URL } from 'utils/constants/clientUrls';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getInitialValuesForSearchForm } from 'utils/form/initialValues';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';

export function DisabledUsers() {
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);
  const [facilities, setFacilities] = useState<FacilityModel[]>([]);
  const [tenants, setTenants] = useState<TenantModel[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    facilitiesService.getFacilities().then(({ data }) => setFacilities(data));
    tenantsService.getTenants().then(({ data }) => setTenants(data));
    usersService.getDisabledTenantUsers({}).then(({ data }) => setUsers(data));
  }, []);

  const filterChangedHandler = useCallback((values: SearchFilterValues) => {
    usersService.getDisabledTenantUsers(values).then(({ data }) => setUsers(data));
  }, []);

  const onEditClickHandler = useCallback((id: number) => {
    navigate(USERS_TENANT_USERS_EDIT_TENANT_USER_URL.route.replace(':userId', String(id)));
  }, []);

  const onActivateClickHandler = useCallback(
    (id: number) => {
      modal.confirm({
        title: t('tenantUsers.confirm.enableUser'),
        icon: <ExclamationCircleFilled />,
        onOk() {
          usersService
            .activateTenantUser(id)
            .then(() => {
              setUsers((models) => models.filter(({ user_id }) => user_id !== id));

              message.success(t('tenantUsers.message.activated'));
            })
            .catch((result) => {
              message.error(result.message);
            });
        },
        className: 'confirm-modal',
        okText: t('tenantUsers.confirm.ok'),
        cancelText: t('tenantUsers.confirm.cancel'),
        okButtonProps: {
          color: '#D50066',
        },
      });
    },
    [t],
  );

  const initialValues = useMemo(() => {
    return getInitialValuesForSearchForm(user, tenants, facilities);
  }, [user?.tenant_id, user?.account_type, facilities, tenants]);

  return (
    <div className="tenant-users-overview-content">
      {contextHolder}
      <TenantUsersSearchForm
        facilities={facilities}
        onFilterChanged={filterChangedHandler}
        tenants={tenants}
        initialValues={initialValues}
      />
      <TenantUsersTable
        users={users}
        columns={getColumns(t, onEditClickHandler, onActivateClickHandler)}
      />
    </div>
  );
}
