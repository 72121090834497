import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';

export type TStore = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
