import React from 'react';
import { Section } from 'components/Section';
import { PurchaseHistoryModel } from 'models/purchaseHistory.model';
import { Table } from 'antd';
import { getColumns } from './config';
import { useTranslation } from 'react-i18next';

type TenantPurchaseHistoryProps = {
  data: PurchaseHistoryModel[];
};

export function TenantPurchaseHistorySection({ data }: TenantPurchaseHistoryProps) {
  const { t } = useTranslation();

  return (
    <Section title={t('purchase.tenantPurchaseHistory')}>
      <Table
        bordered
        rowKey="id"
        className="table"
        locale={{ emptyText: t('table.emptyText') }}
        columns={getColumns(t)}
        dataSource={data}
        pagination={false}
      />
    </Section>
  );
}
