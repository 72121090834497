import { FormConfig } from '../UserForm';
import { confirmRule, nameRule, passwordRule } from 'utils/form/validation';
import { TFunction } from 'i18next';

export function getFormConfig(t: TFunction): FormConfig {
  return {
    email: {
      name: 'email',
      rules: [
        { required: true, message: t('tenantUsers.validation.requiredUserName') },
        { type: 'email', message: t('tenantUsers.validation.emailUserName') },
        { max: 100, message: t('tenantUsers.validation.maxUserName') },
      ],
    },
    password: {
      name: 'password',
      rules: [
        { required: true, message: t('tenantUsers.validation.requiredPassword') },
        passwordRule(8, t),
      ],
    },
    confirmPassword: {
      name: 'confirm_password',
      dependencies: ['password'],
      rules: [
        { required: true, message: t('tenantUsers.validation.requiredPassword') },
        confirmRule(t('tenantUsers.validation.confirmPassword')),
      ],
    },
    displayName: {
      name: 'display_name',
      rules: [
        { required: true, message: t('tenantUsers.validation.requiredAccountName') },
        { max: 100, message: t('tenantUsers.validation.maxAccountName') },
        nameRule(t),
      ],
    },
    phone: {
      name: 'phone',
      rules: [
        { required: true, message: t('tenantUsers.validation.requiredPhone') },
        { max: 15, message: t('tenantUsers.validation.maxPhone') },
        {
          pattern: new RegExp('^(012|\\+12)'),
          message: t('tenantUsers.validation.starWithPhone'),
        },
      ],
    },
    mobilePhoneCode: {
      name: 'mobile_phone_code',
      rules: [
        { required: true, message: t('tenantUsers.validation.requiredPhoneCode') },
        {
          pattern: new RegExp('^(00|0|\\+)?(966|971|965|968|973|974|5|)$'),
          message: 'Incorrect value!',
        },
      ],
    },
    mobilePhone: {
      name: 'mobile_phone',
      rules: [
        { required: true, message: t('tenantUsers.validation.requiredMobile') },
        {
          pattern: new RegExp('^5[0-9]{8}$'),
          message: t('tenantUsers.validation.incorrectValue'),
        },
      ],
    },
    description: {
      name: 'description',
      rules: [{ required: true, message: t('tenantUsers.validation.requiredDescription') }],
    },
    contactPerson: {
      name: 'contact_person',
      rules: [{ max: 100, message: t('tenantUsers.validation.maxContact') }],
    },
    tenantId: {
      name: 'tenant_id',
      rules: [{ required: true, message: t('tenantUsers.validation.tenantRequired') }],
    },
    maxAllowedValidationHours: {
      name: 'max_allowed_validation_hours',
      colon: false,
      rules: [
        {
          required: true,
          message: t('tenantUsers.validation.requiredMaximumAllowedValidationHours'),
          pattern: new RegExp(/^[1-4]$/),
        },
      ],
      rootClassName: 'validation-hours',
    },
    allowEntireSessionValidation: {
      name: 'allow_entire_session_validation',
      rootClassName: 'allow-entire-session-validation',
      colon: false,
      labelCol: {
        span: 12,
      },
    },
  };
}
