export type PaginationModel = {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
};

export const defaultPagination: PaginationModel = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 20,
  totalItems: 0,
  totalPages: 0,
};
