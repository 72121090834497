import {
  overview,
  facility,
  tenant,
  email,
  mobileNumber,
  search,
  userEmail,
  userName,
  tenantName,
  action,
} from './common';

export const tenantUsers = {
  tabs: {
    overview,
    disabledTenantUsers: 'Disabled Tenants Users',
    addTenantUser: 'Add Tenant User',
  },
  search: {
    facility,
    tenant,
    email,
    mobileNumber,
    button: search,
  },
  table: {
    userEmail,
    userName,
    facility,
    tenantName,
    mobileNumber,
    action,
  },
  form: {
    usernameAndPasswordSection: 'Username and Password',
    accountInformationSection: 'Account Information',
    assignTenantSection: 'Assign Tenant(s)',
    validationPermissionSection: 'Validation Permission',
    username: 'Username (Email ID)',
    password: 'Password',
    confirmPassword: 'Confirm password',
    button: 'Create Account',
    accountName: 'Account name',
    mobileNumber,
    phone: 'Phone (Landline)',
    description: 'Description',
    contactPerson: 'Contact person (optional)',
    maximumAllowedValidationHours: 'Maximum Allowed Validation Hours',
    allowEntireSessionValidation: 'Allow Entire Session Validation',
    allowEntireSessionValidationNote:
      'This allows user to have a full day parking session without having to pay the fee',
    updateAccount: 'Update Account',
  },
  message: {
    activated: 'The tenant user has been successfully activated!',
    deactivated: 'The tenant user has been successfully deactivated!',
    created: 'The tenant user has been successfully created!',
    updated: 'The tenant user has been successfully updated!',
  },
  confirm: {
    enableUser: 'Do you want enable user?',
    disable: 'Do you want disable user?',
    ok: 'OK',
    cancel: 'Cancel',
  },
  validation: {
    requiredUserName: 'Please input your username!',
    emailUserName: 'Username is not a valid email!',
    maxUserName: 'Username must be up to ${max} characters!',
    requiredPassword: 'Please input your password!',
    minPassword: 'Password must be at least characters ${min}!',
    containPassword:
      'The password must contain at least one number and one special character and one uppercase and lowercase letter, and at least {{min}} or more characters!',
    confirmPassword: 'The confirm password that you entered do not match!',
    requiredAccountName: 'Please input your account name!',
    maxAccountName: 'Account name must be up to ${max} characters!',
    firstNameLastName: 'The account name must be the first and last name of the user!',
    requiredPhone: 'Please input your phone!',
    maxPhone: 'Phone must be up to ${max} characters!',
    starWithPhone: 'The ${label} must be starting with 012, +12!',
    requiredPhoneCode: 'Please input your mobile phone code!',
    requiredMobile: 'Please input your mobile number!',
    requiredDescription: 'Please input your description!',
    maxContact: 'Contact person must be up to ${max} characters!',
    tenantRequired: 'Please choose your assign tenant!',
    requiredMaximumAllowedValidationHours: 'Please input your maximum allowed validation hours!',
    incorrectValue: 'Incorrect value!',
  },
};
