import { Dispatch } from 'redux';
import { authService } from 'services/auth.service';
import { authActions } from 'store/auth';
import { userActions, userIsLoadingActions } from './reducer';

export const getUserAction = () => {
  const { setUser } = userActions;
  const { setIsLoading } = userIsLoadingActions;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data: user } = await authService.profile();
      dispatch(setUser(user));
    } catch (e) {
      dispatch(authActions.setIsLogged(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const clearUserAction = () => {
  const { setUser } = userActions;
  return async (dispatch: Dispatch) => {
    dispatch(setUser(null));
  };
};
