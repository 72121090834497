import { useAppDispatch } from 'hooks/useAppSelector';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getIsLogged } from 'store/auth/selectors';
import { getUserAction } from 'store/user/actions';
import { LOGIN_URL } from 'utils/constants/clientUrls';

export const PrivateRoute = () => {
  const dispatch = useAppDispatch();
  const isLogged = useSelector(getIsLogged);

  useEffect(() => {
    if (isLogged) {
      dispatch(getUserAction());
    }
  }, [isLogged]);

  if (!isLogged) {
    return <Navigate to={LOGIN_URL.path()} />;
  }

  return <Outlet />;
};
