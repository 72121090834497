import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { authService } from 'services/auth.service';

export const { reducer: isLogged, actions: authActions } = createSlice({
  name: 'auth/isLogged',
  initialState: !!authService.getAccessToken(),
  reducers: {
    setIsLogged: (_, action) => action.payload,
  },
});

export const auth = combineReducers({
  isLogged,
});
