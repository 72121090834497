import React from 'react';

export function PdfFile() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
      <path
        fill="currentColor"
        d="M14.105 20.35c0-.76-.528-1.213-1.459-1.213-.38 0-.637.037-.772.073v2.44c.16.037.356.05.625.05.993 0 1.606-.503 1.606-1.35Zm5.766-1.19a3.86 3.86 0 0 0-.847.075v5.408c.16.037.417.037.65.037 1.692.012 2.796-.92 2.796-2.894.013-1.717-.993-2.625-2.599-2.625Z"
      />
      <path
        fill="currentColor"
        d="M30.918 15.984h-.678v-3.271a.46.46 0 0 0-.006-.062.545.545 0 0 0-.131-.36L24.66 6.077l-.004-.004a.566.566 0 0 0-.255-.16l-.03-.01a.539.539 0 0 0-.127-.015H10.867c-.611 0-1.107.497-1.107 1.107v8.99h-.678c-.874 0-1.582.708-1.582 1.582v8.228c0 .873.709 1.582 1.582 1.582h.678v5.633c0 .61.496 1.107 1.107 1.107h18.266c.61 0 1.107-.497 1.107-1.107v-5.633h.678c.874 0 1.582-.71 1.582-1.582v-8.228c0-.874-.709-1.582-1.582-1.582Zm-20.051-8.99H23.69v5.664c0 .306.248.553.554.553h4.89v2.773H10.867v-8.99Zm13.59 14.73c0 1.582-.576 2.674-1.375 3.348-.87.723-2.194 1.067-3.813 1.067-.969 0-1.655-.062-2.122-.122v-8.12c.687-.11 1.582-.17 2.527-.17 1.569 0 2.587.282 3.384.883.859.637 1.399 1.655 1.399 3.114Zm-14.436 4.33v-8.156c.576-.098 1.386-.171 2.526-.171 1.153 0 1.975.22 2.526.662.527.417.882 1.104.882 1.913 0 .81-.269 1.496-.76 1.962-.638.6-1.582.87-2.686.87-.245 0-.466-.011-.637-.036v2.956h-1.851Zm19.112 6.655H10.867v-5.333h18.266v5.333Zm1.604-13.388H27.56v1.889h2.968v1.52H27.56v3.324h-1.875v-8.266h5.052v1.533Z"
      />
    </svg>
  );
}
