import moment, { Moment } from 'moment';

export function datesDuration(firstDate: Moment, secondDate: Moment) {
  const duration = moment.duration(firstDate.diff(secondDate));

  const result = [];

  if (duration.years()) {
    result.push(`${duration.years()}Y`);
  }

  if (duration.months()) {
    result.push(`${duration.months()}MO`);
  }

  if (duration.days()) {
    result.push(`${duration.days()}D`);
  }

  if (duration.hours()) {
    result.push(`${duration.hours()}H`);
  }

  if (duration.minutes()) {
    result.push(`${duration.minutes()}M`);
  }

  if (duration.seconds()) {
    result.push(`${duration.seconds()}S`);
  }

  return result.join(' ');
}
