import { Router } from 'components/Routes/Router';
import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { store } from 'store';
import { themeConfig } from 'themeConfig';
import { initLanguageAttributes } from 'utils/language/helper';
import { useTranslation } from 'react-i18next';
import { LanguageEnum } from 'utils/enums/language';
import ar from 'antd/locale/ar_EG';
import { Locale } from 'antd/lib/locale';

function App() {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState<Locale>();

  useEffect(() => {
    initLanguageAttributes(i18n.language as LanguageEnum);
    setLocale(i18n.language === LanguageEnum.Arabic ? ar : undefined);
  }, [i18n.language]);

  return (
    <Provider store={store}>
      <ConfigProvider theme={themeConfig} locale={locale}>
        <Router />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
