import { Url } from 'router/url';

export const LOGIN_URL = new Url('/login');
export const VALIDATE_SESSIONS_URL = new Url('/validate-sessions');
export const HOURS_BALANCE_URL = new Url('/hours_balance');

export const USERS_URL = new Url('/users');
export const USERS_TENANT_ADMINS_URL = new Url('/users/tenant_admins');
export const USERS_TENANT_USERS_URL = new Url('/users/tenant_users');
export const USERS_TENANT_USERS_OVERVIEW_URL = new Url('/users/tenant_users/overview');
export const USERS_TENANT_USERS_DISABLED_TENANT_USERS_URL = new Url(
  '/users/tenant_users/disabled_tenant_users',
);
export const USERS_TENANT_USERS_ADD_TENANT_USER_URL = new Url(
  '/users/tenant_users/add_tenant_user',
);
export const USERS_TENANT_USERS_EDIT_TENANT_USER_URL = new Url('/users/tenant_users/:userId/edit');

export const REPORTS_URL = new Url('/reports');

export const PURCHASE_URL = new Url('/purchase');
export const PURCHASE_OVERVIEW_URL = new Url('/purchase/overview');
export const PURCHASE_BALANCE_HISTORY_URL = new Url('/purchase/balance-history');

export const PURCHASE_PAYMENT_CHECKOUT_URL = new Url('/purchase/checkout/:type');

export const BANK_TRANSFER_URL = new Url('/bank-transfer');
