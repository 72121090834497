import { useEffect, useState } from 'react';
import { facilitiesService } from 'services/facility.service';
import { FacilityModel } from 'models/facility.model';
import { tenantsService } from 'services/tenants.service';
import { FacilitiesGroup } from '../components/UserForm';

export function useFacilities() {
  const [data, setData] = useState<FacilitiesGroup[]>([]);

  useEffect(() => {
    facilitiesService
      .getFacilities()
      .then(({ data: facilitiesData }: { data: FacilityModel[] }) => {
        tenantsService.getTenants().then(({ data: tenantsData }) => {
          const result = facilitiesData
            .map((facility) => ({
              ...facility,
              tenants: tenantsData.filter(
                ({ facility_id }) => facility_id === facility.facility_id,
              ),
            }))
            .filter((facility) => facility.tenants.length > 0);

          setData(result);
        });
      });
  }, []);

  return { data };
}
