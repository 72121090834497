export const login = {
  greeting: 'Welcome to our community',
  description: 'All you need to manage, validate and pay in one place',
  loginBy: 'Login by using your registered email and password',
  forgetPassword: 'Forget password?',
  rememberMe: 'Remember me',
  loginBtn: 'Login',
  loginPlaceholder: 'Enter email or username',
  passwordPlaceholder: 'Enter password',
};
