import { Section } from 'components/Form/Section';
import { Button, Checkbox, Col, Form, FormItemProps, Input, Radio, Row, Typography } from 'antd';
import { chunk } from 'utils/array/helper';
import React, { useId } from 'react';
import { TenantRadioGroup } from 'components/Form/TenantRadioGroup';
import { FacilitiesGroup, FormValues, GUTTER } from './helper';
import { ValidIcon } from 'components/ValidIcon';

import './UserForm.scss';
import { useTranslation } from 'react-i18next';

export type FormConfig = Record<string, FormItemProps>;

type Props = {
  onFinish: (values: FormValues) => void;
  values?: FormValues;
  facilities?: FacilitiesGroup[];
  submitButtonText: React.ReactNode;
  formConfig: FormConfig;
};

export function UserForm({
  onFinish,
  values,
  submitButtonText,
  formConfig,
  facilities = [],
}: Props) {
  const { t } = useTranslation();

  return (
    <Form<FormValues>
      onFinish={onFinish}
      className="add-tenant-users-form"
      autoComplete="off"
      initialValues={values}
    >
      <Section title={t('tenantUsers.form.usernameAndPasswordSection')} showDivider>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item {...formConfig.email}>
              <Input placeholder={t('tenantUsers.form.username')} autoComplete="off" />
            </Form.Item>
            <ValidIcon />
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item {...formConfig.password}>
              <Input.Password
                placeholder={t('tenantUsers.form.password')}
                autoComplete="new-password"
              />
            </Form.Item>
            <ValidIcon />
          </Col>
          <Col span={12}>
            <Form.Item {...formConfig.confirmPassword}>
              <Input.Password placeholder={t('tenantUsers.form.confirmPassword')} />
            </Form.Item>
            <ValidIcon />
          </Col>
        </Row>
      </Section>

      <Section title={t('tenantUsers.form.accountInformationSection')} showDivider>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item {...formConfig.displayName}>
              <Input placeholder={t('tenantUsers.form.accountName')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...formConfig.phone}>
              <Input placeholder={t('tenantUsers.form.phone')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Row gutter={16}>
              <Col className="phone-code-col">
                <Form.Item {...formConfig.mobilePhoneCode}>
                  <Input placeholder="+966" />
                </Form.Item>
              </Col>
              <Col className="mobile-phone-col">
                <Form.Item {...formConfig.mobilePhone}>
                  <Input
                    type="number"
                    placeholder={t('tenantUsers.form.mobileNumber')}
                    className="mobile-phone-input"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item {...formConfig.description}>
              <Input placeholder={t('tenantUsers.form.description')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item {...formConfig.contactPerson}>
              <Input placeholder={t('tenantUsers.form.contactPerson')} />
            </Form.Item>
          </Col>
        </Row>
      </Section>

      <Section title={t('tenantUsers.form.assignTenantSection')}>
        <Form.Item {...formConfig.tenantId}>
          <Radio.Group className="tenant-group">
            {chunk(facilities, 2).map((chunks) => (
              <Row gutter={GUTTER} key={useId()}>
                {chunks.map(({ facility_id, facility_name, tenants }) => (
                  <Col span={12} key={facility_id}>
                    <TenantRadioGroup title={facility_name} tenants={tenants} />
                  </Col>
                ))}
              </Row>
            ))}
          </Radio.Group>
        </Form.Item>
      </Section>

      <Section title={t('tenantUsers.form.validationPermissionSection')}>
        <Row>
          <Col md={16} xs={24}>
            <Form.Item
              label={t('tenantUsers.form.maximumAllowedValidationHours')}
              {...formConfig.maxAllowedValidationHours}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t('tenantUsers.form.allowEntireSessionValidation')}
          {...formConfig.allowEntireSessionValidation}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Typography.Text className="form-item-note">
          {t('tenantUsers.form.allowEntireSessionValidationNote')}
        </Typography.Text>
      </Section>

      <Button className="submit-button" htmlType="submit" type="primary">
        {submitButtonText}
      </Button>
    </Form>
  );
}
