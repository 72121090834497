import { api } from 'lib/api';
import { UserModel } from 'models/user.model';
import { UserDetailsModel } from 'models/userDetails.model';

type GetTenantUserParams = { facility_number?: number; email?: string; mobile?: string };
const getTenantUsers = (params: GetTenantUserParams = {}) =>
  api.get<UserModel[]>('/users/tenant_users', { params });

const getUsers = () =>
  api.get('/users').then(({ data }) => {
    return data.map(({ id, username }: { id: number; username: string }) => ({
      user_id: id,
      display_name: username,
    }));
  });

const getTenantUserDetails = (id: number) => api.get<UserDetailsModel>(`/users/tenant_users/${id}`);

const getDisabledTenantUsers = (params: GetTenantUserParams) =>
  api.get<UserModel[]>('/users/disabled_tenant_users', { params });

type GetTenantAdminsParams = { facility_number?: number; email?: string; mobile?: string };
const getTenantAdmins = (params: GetTenantAdminsParams) =>
  api.get<UserModel[]>('/users/tenant_admins', { params });

const disableTenantUser = (id: number) => api.patch(`/users/disable_tenant_user/${id}`);
const activateTenantUser = (id: number) => api.patch(`/users/activate_tenant_user/${id}`);

type CreteTenantUserData = {
  username: string;
  display_name: string;
  email: string;
  password: string;
  contact_person?: string;
  mobile: string;
  phone: string;
  description: string;
  max_allowed_validation_hours: number;
  allow_entire_session_validation: boolean;
  tenant_id: number;
};

const createTenantUser = (data: CreteTenantUserData) => api.post('/users/tenant_users', data);

type EditTenantUserData = {
  username: string;
  display_name: string;
  email: string;
  password?: string;
  contact_person?: string;
  mobile: string;
  phone: string;
  description: string;
  max_allowed_validation_hours: number;
  allow_entire_session_validation: boolean;
  tenant_id: number;
};
const editTenantUser = (id: number, data: EditTenantUserData) =>
  api.patch(`/users/tenant_users/${id}`, data);

export const usersService = {
  getUsers,
  getTenantUsers,
  getTenantUserDetails,
  getDisabledTenantUsers,
  getTenantAdmins,
  disableTenantUser,
  activateTenantUser,
  createTenantUser,
  editTenantUser,
};
