import { AccountType, UserModel } from 'models/user.model';
import { ParkingSessionModel } from 'models/parkingSessionModel';
import { hasRole } from 'utils/permission/role';

export function canEditParkingValidation(model: ParkingSessionModel, user: UserModel): boolean {
  if (
    !hasRole(user?.account_type, [
      AccountType.Admin,
      AccountType.FacilityOwner,
      AccountType.TenantAdmin,
      AccountType.TenantUser,
    ])
  ) {
    return false;
  }

  if (user?.account_type === AccountType.TenantUser && user?.user_id !== model.validated_by_user) {
    return false;
  }

  return !(
    user?.account_type === AccountType.TenantAdmin && user?.tenant_id !== model.validated_by_tenant
  );
}
