import React, { useCallback, useState } from 'react';
import { Content } from 'components/Content';
import { BankTransferSearchForm, BankTransferPurchaseForm } from './components';
import { TenantDetailsModel } from 'models/tenant.model';
import { tenantsService } from 'services/tenants.service';

import './BankTransfer.scss';

export function BankTransfer() {
  const [tenant, setTenant] = useState<TenantDetailsModel>();

  const onLoadHandler = useCallback((tenantId: number) => {
    tenantsService.getTenantDetails(tenantId).then(({ data }) => setTenant(data));
  }, []);

  return (
    <Content className="bank-transfer">
      <BankTransferSearchForm onLoad={onLoadHandler} />
      {tenant && <BankTransferPurchaseForm tenant={tenant} />}
    </Content>
  );
}
