import React, { useCallback, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { ShopOutlined, TeamOutlined } from '@ant-design/icons';
import { FullWidthButton } from 'components/Button/FullWidthButton';
import { FacilityModel } from 'models/facility.model';
import { TenantModel } from 'models/tenant.model';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import { hasRole } from 'utils/permission/role';
import { AccountType } from 'models/user.model';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';

import './PurchaseOverviewSearchForm.scss';

type PurchaseOverviewSearchFormProps = {
  facilities?: FacilityModel[];
  tenants?: TenantModel[];
  onLoad: (tenantId: number) => void;
};

type FormValues = {
  facility_id: number;
  tenant_id: number;
};

export function PurchaseOverviewSearchForm({
  facilities = [],
  tenants = [],
  onLoad,
}: PurchaseOverviewSearchFormProps) {
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);
  const [form] = useForm<FormValues>();
  const [filteredTenants, setFilteredTenants] = useState<TenantModel[]>([]);

  const onFormSubmitHandler = (values: FormValues) => {
    onLoad(values.tenant_id);
  };

  const onChangeFacilityIdHandler = useCallback(
    (value: number) => {
      form.resetFields(['tenant_id']);
      setFilteredTenants(tenants?.filter(({ facility_id }) => facility_id === value));
    },
    [tenants],
  );

  useEffect(() => {
    if (hasRole(user?.account_type, [AccountType.FacilityOwner])) {
      const facilityId = facilities?.[0]?.facility_id;
      if (facilityId) {
        form.setFieldsValue({
          facility_id: facilityId,
        });

        onChangeFacilityIdHandler(facilityId);
      }
    }
  }, [user?.account_type, facilities, tenants]);

  return (
    <Form<FormValues>
      form={form}
      className="purchase-search-form"
      layout="inline"
      onFinish={onFormSubmitHandler}
    >
      <Form.Item name="facility_id" rules={[{ required: true, message: t('validation.required') }]}>
        <Select
          placeholder={
            <div className="select-icon">
              <ShopOutlined /> {t('purchase.form.facility')}
            </div>
          }
          onChange={onChangeFacilityIdHandler}
          allowClear
        >
          {facilities.map(({ facility_id, facility_name }) => (
            <Select.Option key={facility_id} value={facility_id}>
              {facility_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="tenant_id" rules={[{ required: true, message: t('validation.required') }]}>
        <Select
          placeholder={
            <div className="select-icon">
              <TeamOutlined /> {t('purchase.form.tenant')}
            </div>
          }
          disabled={filteredTenants.length === 0}
          allowClear
        >
          {filteredTenants.map(({ tenant_id, tenant_name }) => (
            <Select.Option key={tenant_id} value={tenant_id}>
              {tenant_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <FullWidthButton className="submit-button" htmlType="submit" type="primary">
        {t('purchase.form.search')}
      </FullWidthButton>
    </Form>
  );
}
