import {
  plateNumber,
  tenantName,
  facilityName,
  customerId,
  startDate,
  endDate,
  date,
  exportLabel,
  userName,
  search,
  confirm,
} from './common';
import { ReportType } from 'utils/enums/reportType';

export const report = {
  form: {
    plateNumber,
    tenantName,
    facilityName,
    customerId,
    startDate,
    endDate,
    tenantUser: 'مستخدم الوحدة',
    customerMobileNumber: 'رقم جوال العميل',
    search,
  },
  table: {
    export: exportLabel,
    validatedHours: 'ساعات الاعتماد',
    durationSession: 'مدة الجلسة',
    customerMobile: 'جوال العميل',
    customerId,
    plateNumber,
    userName,
    tenantName,
    facilityName,
    date,
  },
  exportModal: {
    title: 'حدد نوع الملف المراد تحميله',
    [ReportType.Pdf]: 'ملف PDF',
    [ReportType.Excel]: 'ملف Excel',
    confirm,
  },
};
