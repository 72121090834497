import { AccountType, UserModel } from 'models/user.model';
import { useEffect, useState } from 'react';
import { usersService } from 'services/users.service';
import { hasRole } from 'utils/permission/role';

export function useTenantUsers(user: UserModel) {
  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    if (hasRole(user.account_type, [AccountType.Admin, AccountType.TenantAdmin])) {
      usersService.getUsers().then((data) => setUsers(data));
      return;
    }

    usersService.getTenantUsers().then(({ data }) => setUsers(data));
  }, []);

  return users;
}
