export function chunk<T>(arr: T[], chunkSize: number) {
  const size = Math.ceil(arr.length / chunkSize);

  return Array.from({ length: chunkSize }, (_, i) => arr.slice(i * size, i * size + size));
}

export function pluck<T, V>(arr: T[], key: keyof T, value: keyof T) {
  const record: Record<number | string, V> = {};

  arr.forEach((result) => {
    record[result[key] as number | string] = result[value] as V;
  });

  return record;
}

export function wrap<T>(value: T | T[]): T[] {
  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}
