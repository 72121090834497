import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getIsLogged } from 'store/auth/selectors';

export const PublicRoute = () => {
  const isLogged = useAppSelector(getIsLogged);

  if (isLogged) return <Navigate to={'/'} />;

  return <Outlet />;
};
