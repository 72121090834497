import { TableProps } from 'antd/lib';
import moment from 'moment';
import { TFunction } from 'i18next';

export function getColumns(t: TFunction): TableProps['columns'] {
  return [
    {
      dataIndex: 'purchase_date',
      title: t('purchase.date'),
      render(value) {
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      dataIndex: 'username',
      title: t('purchase.user'),
    },
    {
      dataIndex: 'total_hours',
      title: t('purchase.totalHours'),
    },
    {
      dataIndex: 'paid_amount',
      title: t('purchase.paidAmount'),
    },
  ];
}
