import { Table } from 'antd';
import { UserModel } from 'models/user.model';
import React from 'react';
import { TableProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';

type Props = {
  users: UserModel[];
  columns: TableProps<UserModel>['columns'];
};

export const TenantUsersTable = ({ users, columns }: Props) => {
  const { t } = useTranslation();

  return (
    <Table
      scroll={{ x: true }}
      bordered
      rowKey="index"
      className="table"
      locale={{ emptyText: t('table.emptyText') }}
      columns={columns}
      dataSource={users as never}
      pagination={false}
    />
  );
};
