import React, { useEffect } from 'react';
import Icon, { PhoneOutlined, ShopOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FacilityModel } from 'models/facility.model';
import { TenantModel } from 'models/tenant.model';
import { CalendarIcon, CustomerIcon, PlateNumberIcon } from 'components/Icons';
import { FullWidthButton } from 'components/Button/FullWidthButton';
import { SearchFormValues } from 'features/Reports/Reports';
import { Dayjs } from 'dayjs';
import { UserModel } from 'models/user.model';
import { useTranslation } from 'react-i18next';

import './ReportsSerachForm.scss';

type Props = {
  facilities?: FacilityModel[];
  tenants?: TenantModel[];
  tenantUsers?: UserModel[];
  onFormSubmit: (values: SearchFormValues) => void;
  initialValues?: FormValues;
};

export type FormValues = {
  facility_id?: number;
  tenant_id?: number;
  plate_number?: string;
  tenant_user_id?: number;
  customer_id?: number;
  customer_mobile?: number;
  date?: Dayjs[];
};

export function ReportsSearchForm({
  onFormSubmit,
  initialValues = {},
  facilities = [],
  tenants = [],
  tenantUsers = [],
}: Props) {
  const { t } = useTranslation();
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const onFormSubmitHandler = ({ date, ...rest }: FormValues) => {
    const newParams: SearchFormValues = { ...rest };

    if (date?.[0]) {
      newParams.date_from = date[0].format('YYYY-MM-DD HH:mm:ss');
    }

    if (date?.[1]) {
      newParams.date_to = date[1].format('YYYY-MM-DD HH:mm:ss');
    }

    onFormSubmit(newParams);
  };

  return (
    <Form<FormValues>
      className="reports-search-form"
      form={form}
      layout="inline"
      onFinish={onFormSubmitHandler}
    >
      <Form.Item name="facility_id">
        <Select
          placeholder={
            <div className="select-icon">
              <ShopOutlined /> {t('report.form.facilityName')}
            </div>
          }
          allowClear
        >
          {facilities.map(({ facility_id, facility_name }) => (
            <Select.Option key={facility_id} value={facility_id}>
              {facility_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="tenant_id">
        <Select
          placeholder={
            <div className="select-icon">
              <TeamOutlined /> {t('report.form.tenantName')}
            </div>
          }
          allowClear
        >
          {tenants.map(({ tenant_id, tenant_name }) => (
            <Select.Option key={tenant_id} value={tenant_id}>
              {tenant_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="plate_number">
        <Input
          prefix={<Icon component={PlateNumberIcon} />}
          placeholder={t('report.form.plateNumber')}
          allowClear
        />
      </Form.Item>

      <Form.Item name="tenant_user_id">
        <Select
          placeholder={
            <div className="select-icon">
              <UserOutlined /> {t('report.form.tenantUser')}
            </div>
          }
          allowClear
        >
          {tenantUsers.map(({ user_id, display_name }) => (
            <Select.Option key={user_id} value={user_id}>
              {display_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="customer_id">
        <Input
          prefix={<Icon component={CustomerIcon} />}
          placeholder={t('report.form.customerId')}
          allowClear
        />
      </Form.Item>

      <Form.Item name="customer_mobile">
        <Input
          prefix={<PhoneOutlined />}
          placeholder={t('report.form.customerMobileNumber')}
          allowClear
        />
      </Form.Item>

      <Form.Item name="date">
        <DatePicker.RangePicker
          placement="bottomRight"
          suffixIcon={<Icon component={CalendarIcon} />}
          showTime={{
            format: 'HH:mm',
          }}
          allowClear
          placeholder={[t('report.form.startDate'), t('report.form.endDate')]}
        />
      </Form.Item>

      <FullWidthButton className="submit-button" htmlType="submit" type="primary">
        {t('report.form.search')}
      </FullWidthButton>
    </Form>
  );
}
