import { api } from 'lib/api';
import { UserModel } from 'models/user.model';

type LoginParams = {
  login: string;
  password: string;
  remember: boolean;
};
type LoginResponse = {
  access_token: string;
  remember: boolean;
};
const login = async (params: LoginParams) => {
  const {
    data: { access_token, remember },
  } = await api.post<LoginResponse>('/auth/login', params);

  if (remember) {
    localStorage.setItem('access_token', access_token);
  } else {
    sessionStorage.setItem('access_token', access_token);
  }
};

const logout = () => {
  localStorage.removeItem('access_token');
  sessionStorage.removeItem('access_token');
};

const profile = async () => api.get<UserModel>('auth/profile');

const getAccessToken = () =>
  localStorage.getItem('access_token') || sessionStorage.getItem('access_token');

export const authService = {
  profile,
  login,
  logout,
  getAccessToken,
};
