import {
  plateNumber,
  tenantName,
  facilityName,
  customerId,
  startDate,
  endDate,
  date,
  exportLabel,
  userName,
  search,
  confirm,
} from './common';
import { ReportType } from 'utils/enums/reportType';

export const report = {
  form: {
    plateNumber,
    tenantName,
    facilityName,
    customerId,
    startDate,
    endDate,
    tenantUser: 'Tenant User',
    customerMobileNumber: 'Customer Mobile Number',
    search,
  },
  table: {
    export: exportLabel,
    validatedHours: 'Validated Hours',
    durationSession: 'Duration session',
    customerMobile: 'Customer Mobile',
    customerId,
    plateNumber,
    userName,
    tenantName,
    facilityName,
    date,
  },
  exportModal: {
    title: 'Select Type Of File To Be Exported',
    [ReportType.Pdf]: 'PDF File',
    [ReportType.Excel]: 'Excel File',
    confirm,
  },
};
