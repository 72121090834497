import { overview } from './common';

export const sidebar = {
  validateSessions: 'اعتماد رسوم المواقف',
  reports: 'التقارير',
  users: 'المستخدمين',
  tenantAdmins: 'مسؤول الوحدة',
  tenantUsers: 'مستخدمين الوحدة',
  purchase: 'المشتريات',
  overview,
  balanceHistory: 'تاريخ الرصيد',
  bankTransfer: 'Bank Transfer',
};
