import { Table } from 'antd';
import { getColumns } from './columns';
import React from 'react';
import { PaginationModel } from 'models/paginationModel';
import { getItemRender } from 'components/Table/helper';
import { ReportModel } from 'models/report.model';
import { useTranslation } from 'react-i18next';

type Props = {
  data?: ReportModel[];
  loading: boolean;
  pagination: PaginationModel;
  onChangePagination: (page: number, pageSize: number) => void;
};

export const ReportsTable = ({ pagination, onChangePagination, data = [], loading }: Props) => {
  const { t } = useTranslation();

  return (
    <Table<ReportModel>
      scroll={{ x: true }}
      bordered
      rowKey="index"
      className="table"
      columns={getColumns(t)}
      dataSource={data}
      loading={loading}
      pagination={{
        total: pagination.totalItems,
        pageSize: pagination.itemsPerPage,
        current: pagination.currentPage,
        itemRender: getItemRender(t, pagination),
        onChange: onChangePagination,
        showSizeChanger: false,
      }}
      locale={{ emptyText: t('table.emptyText') }}
    />
  );
};
