import { api } from 'lib/api';
import { PaginationModel } from 'models/paginationModel';
import { ReportModel } from 'models/report.model';

const getReports = (params: object) =>
  api.get<{ items: ReportModel[]; meta: PaginationModel }>(`/reports`, { params });

export default {
  getReports,
};
