import React, { useCallback } from 'react';
import { Section } from 'components/Section';
import { DetailsGrid } from 'components/DetailsGrid';
import { getColumns } from './config';
import { Button } from 'antd';
import { TenantDetailsModel } from 'models/tenant.model';
import { Row } from '../Row';
import { RateModel } from 'models/rate.model';
import { Guard } from 'components/Guard';
import { AccountType } from 'models/user.model';
import { useTranslation } from 'react-i18next';
import { PURCHASE_PAYMENT_CHECKOUT_URL } from 'utils/constants/clientUrls';
import { PaymentMethodType } from 'utils/enums/paymentMethods';
import { useNavigate } from 'react-router-dom';

type TenantDetailsProps = {
  tenant: TenantDetailsModel;
  rate: RateModel;
};

export function PostPaidInformationSection({ tenant, rate }: TenantDetailsProps) {
  const { t } = useTranslation();
  const totalValidatedHours = tenant.total_validated_hours - tenant.total_purchased_hours;
  const dueAmountValue = rate.rate * totalValidatedHours;
  const navigate = useNavigate();

  const onPayNowClickHandler = useCallback(() => {
    navigate(PURCHASE_PAYMENT_CHECKOUT_URL.path({ type: PaymentMethodType.VisaPayment }), {
      state: {
        tenant,
        rate,
        hours: totalValidatedHours,
      },
    });
  }, [tenant, rate, totalValidatedHours]);

  return (
    <Section title={t('purchase.postPaidInformation')} className="post-paid-information-section">
      <DetailsGrid data={tenant} columns={getColumns(t)} />
      <Row
        data={[
          t('purchase.dueAmount'),
          `${dueAmountValue > 0 ? dueAmountValue : 0} ${rate.currency}`,
        ]}
      />
      <Guard roles={AccountType.TenantAdmin}>
        <Button type="primary" disabled={dueAmountValue <= 0} onClick={onPayNowClickHandler}>
          {t('purchase.payNow')}
        </Button>
      </Guard>
    </Section>
  );
}
