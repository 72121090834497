import { ExcelFile, PdfFile } from 'components/Icons';
import { ReportType } from 'utils/enums/reportType';

export const reportTypes = [
  {
    key: ReportType.Excel,
    icon: ExcelFile,
  },
  {
    key: ReportType.Pdf,
    icon: PdfFile,
  },
];
