import React from 'react';

import './Logo.scss';

import ParkyyLogo from '../../assets/images/ParkyyLogo.png';

export const Logo = () => {
  return (
    <div className="logo">
      <img src={ParkyyLogo} alt="Parkyy logo" />
    </div>
  );
};
