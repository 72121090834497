import { Columns } from 'components/DetailsGrid';
import { TFunction } from 'i18next';

export function getColumns(t: TFunction): Columns<{
  facility_name: string;
  tenant_name: string;
  validation_type: string;
}>[] {
  return [
    {
      index: 'facility_name',
      title: t('purchase.facilityName'),
    },
    {
      index: 'tenant_name',
      title: t('purchase.tenantName'),
    },
    {
      index: 'validation_type',
      title: t('purchase.validationType'),
      render(value) {
        return t(`purchase.validationTypeLabels.${value as 'prepaid' | 'postpaid'}`);
      },
    },
  ];
}
