import React from 'react';
import { Columns } from 'components/DetailsGrid';
import { InputNumber } from 'antd';
import { RateModel } from 'models/rate.model';
import { TFunction } from 'i18next';

export const getColumns = (
  t: TFunction,
  onValueChange: (value: number | null) => void,
): Columns<RateModel & { value: number | null }>[] => [
  {
    index: 'value',
    title: t('purchase.hoursToBePurchased'),
    render(value) {
      return (
        <InputNumber<number> value={value as number} onChange={onValueChange} placeholder="100" />
      );
    },
  },
  {
    index: 'total_amount',
    title: t('purchase.totalAmount'),
    render(_, index, record) {
      return `${(record.value || 0) * record.rate} ${record.currency}`;
    },
  },
];
