import React, { useCallback, useMemo, useState } from 'react';
import { message, Modal, Table } from 'antd';
import { getColumns } from './getColumns';
import { ParkingSessionModel } from 'models/parkingSessionModel';
import parkingSessionService from 'services/parkingSession.service';
import { ValidateSessionSteps } from './components/ValidateSessionSteps';
import { PaginationModel } from 'models/paginationModel';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { getItemRender } from 'components/Table/helper';
import { useTranslation } from 'react-i18next';

import './ValidateSessionTable.scss';

type ValidateSessionTableProps = {
  data: ParkingSessionModel[];
  loading: boolean;
  setData: (data: ParkingSessionModel[]) => void;
  pagination: PaginationModel;
  onChangePagination: (page: number, pageSize: number) => void;
};

export function ValidateSessionTable({
  data,
  loading,
  setData,
  pagination,
  onChangePagination,
}: ValidateSessionTableProps) {
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);
  const [validateEntry, setValidateEntry] = useState<ParkingSessionModel>();

  const handleCancel = () => {
    setValidateEntry(undefined);
  };

  const onClickHandler = useCallback((entry: ParkingSessionModel) => {
    setValidateEntry(entry);
  }, []);

  const onConfirmHandler = useCallback(
    (duration: number) => {
      if (!validateEntry) {
        return;
      }

      const hoursValidated = duration;

      parkingSessionService
        .editParkingSession(validateEntry.session_id, {
          validation_duration: hoursValidated,
          entire_session: duration === 0,
        })
        .then(() => {
          const copy = [...data];

          const index = copy.findIndex(({ session_id }) => validateEntry.session_id === session_id);

          if (index >= 0) {
            copy[index] = {
              ...copy[index],
              hours_validated: hoursValidated,
              validated_by_tenant: user?.tenant_id,
              validated_by_user: user?.user_id,
            };

            setData(copy);
          }

          message.success(t('validateSessions.messages.validated'));
          setValidateEntry(undefined);
        })
        .catch((response) => {
          message.error(response?.response?.data?.message || response.message);
        });
    },
    [validateEntry, user?.tenant_id, user?.user_id, t],
  );

  const columns = useMemo(() => getColumns(t, onClickHandler), [t]);

  return (
    <>
      <Table
        scroll={{ x: true }}
        bordered
        rowKey="id"
        className="validate-session-table table"
        columns={columns}
        dataSource={data}
        loading={loading}
        locale={{ emptyText: t('table.emptyText') }}
        pagination={{
          total: pagination.totalItems,
          pageSize: pagination.itemsPerPage,
          current: pagination.currentPage,
          itemRender: getItemRender(t, pagination),
          onChange: onChangePagination,
          showSizeChanger: false,
        }}
      />
      <Modal
        destroyOnClose
        open={!!validateEntry}
        onCancel={handleCancel}
        footer={false}
        rootClassName="validate-session-steps-modal"
      >
        {validateEntry && (
          <ValidateSessionSteps entry={validateEntry} onConfirm={onConfirmHandler} />
        )}
      </Modal>
    </>
  );
}
