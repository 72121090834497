import React, { useCallback, useState } from 'react';
import { DatePicker, Form, Input, Space } from 'antd';
import { FullWidthButton } from 'components/Button/FullWidthButton';
import { CalendarIcon, CustomerIcon, PlateNumberIcon } from 'components/Icons';
import { SearchFilterValues } from './types';
import type { Dayjs } from 'dayjs';
import { MobileIcon, Icon } from 'components/Icons';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';

import './ValidateSessionSearchForm.scss';

type ValidateSessionSearchFormProps = {
  onChange: (params: SearchFilterValues) => void;
};

type SearchFilterData = {
  plate_number?: string;
  customer_id?: number;
  mobile?: string;
  date?: Dayjs[];
};

export function ValidateSessionSearchForm({ onChange }: ValidateSessionSearchFormProps) {
  const { t } = useTranslation();
  const [required, setRequired] = useState(true);
  const [form] = useForm();

  const onFinish = useCallback(({ date, ...rest }: SearchFilterData) => {
    const newParams: SearchFilterValues = { ...rest };

    if (date?.[0]) {
      newParams.date_from = date[0].format('YYYY-MM-DD HH:mm:ss');
    }

    if (date?.[1]) {
      newParams.date_to = date[1].format('YYYY-MM-DD HH:mm:ss');
    }

    onChange(newParams);
  }, []);

  const onValuesChange = (changedValue: SearchFilterData, values: SearchFilterValues) => {
    const length = Object.values(values).filter(Boolean).length;

    setRequired(length === 0);
    form.validateFields();
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="validate-session-search-form"
      requiredMark={false}
      onValuesChange={onValuesChange}
    >
      <Space direction="horizontal" className="column-action-space">
        <Form.Item
          name="plate_number"
          rules={[{ required, message: t('validateSessions.validation.requiredPlateNumber') }]}
        >
          <Input
            prefix={<Icon component={PlateNumberIcon} />}
            placeholder={t('validateSessions.plateNumber')}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="customer_id"
          rules={[{ required, message: t('validateSessions.validation.requiredCustomerId') }]}
        >
          <Input
            prefix={<Icon component={CustomerIcon} />}
            placeholder={t('validateSessions.customerId')}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="mobile"
          rules={[{ required, message: t('validateSessions.validation.requiredMobileNumber') }]}
        >
          <Input
            prefix={<Icon component={MobileIcon} />}
            placeholder={t('validateSessions.mobileNumber')}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="date"
          rules={[{ required, message: t('validateSessions.validation.requiredDateTime') }]}
        >
          <DatePicker.RangePicker
            size="small"
            placement="bottomLeft"
            suffixIcon={<Icon component={CalendarIcon} />}
            showTime={{
              format: 'HH:mm',
            }}
            placeholder={[t('validateSessions.startDate'), t('validateSessions.endDate')]}
            allowClear
          />
        </Form.Item>
      </Space>

      <FullWidthButton className="submit-button" htmlType="submit" type="primary">
        {t('validateSessions.search')}
      </FullWidthButton>
    </Form>
  );
}
