import { overview } from './common';

export const sidebar = {
  validateSessions: 'Validate Sessions',
  reports: 'Reports',
  users: 'Users',
  tenantAdmins: 'Tenant Admins',
  tenantUsers: 'Tenant Users',
  purchase: 'Purchase',
  overview,
  balanceHistory: 'Balance history',
  bankTransfer: 'Bank Transfer',
};
