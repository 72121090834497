import React from 'react';

export function ClockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 24">
      <g clipPath="url(#clock)">
        <path
          fill="currentColor"
          d="M11 2c5.063 0 9.167 4.477 9.167 10S16.063 22 11 22s-9.167-4.477-9.167-10S5.937 2 11 2Zm0 2c-1.945 0-3.81.843-5.185 2.343C4.439 7.843 3.667 9.878 3.667 12s.772 4.157 2.148 5.657C7.19 19.157 9.055 20 11 20c1.945 0 3.81-.843 5.185-2.343 1.376-1.5 2.148-3.535 2.148-5.657s-.772-4.157-2.148-5.657C14.81 4.843 12.945 4 11 4Zm0 2c.225 0 .441.09.609.253.168.162.275.387.301.63l.007.117v4.586l2.481 2.707c.165.18.26.421.267.675.007.254-.075.502-.229.692a.893.893 0 0 1-.6.334.863.863 0 0 1-.648-.204l-.086-.083-2.75-3a1.037 1.037 0 0 1-.26-.576L10.083 12V7c0-.265.097-.52.269-.707A.88.88 0 0 1 11 6Z"
        />
      </g>
      <defs>
        <clipPath id="clock">
          <path fill="#fff" d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
