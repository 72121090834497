import { ItemType } from 'rc-collapse/es/interface';
import LevelsIcon from 'assets/images/levels-icon.svg';
import ParkingIcon from 'assets/images/parking-icon.svg';
import SaleIcon from 'assets/images/sale-icon.svg';
import UserIcon from 'assets/images/user-icon.svg';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import {
  BANK_TRANSFER_URL,
  PURCHASE_BALANCE_HISTORY_URL,
  PURCHASE_OVERVIEW_URL,
  USERS_TENANT_ADMINS_URL,
  USERS_TENANT_USERS_OVERVIEW_URL,
  VALIDATE_SESSIONS_URL,
} from 'utils/constants/clientUrls';
import { AccountType } from 'models/user.model';
import { TFunction } from 'i18next';
import { Icon } from 'components/Icons';
import BankTransferIcon from 'components/Icons/components/BankTransferIcon';

type CollapseItem = ItemType & { access?: AccountType[] };

export const getItems: (
  t: TFunction,
  panelStyle: CSSProperties,
  pathname: string,
) => CollapseItem[] = (t, panelStyle, pathname) => [
  {
    key: 'validate-sessions',
    className: 'not-collapsible',
    label: (
      <Link to={VALIDATE_SESSIONS_URL.route}>
        <img src={ParkingIcon} alt={t('sidebar.validateSessions')} />
        {t('sidebar.validateSessions')}
      </Link>
    ),
    style: panelStyle,
    showArrow: false,
    access: [AccountType.TenantUser, AccountType.TenantAdmin],
  },
  {
    key: 'reports',
    className: 'not-collapsible',
    label: (
      <Link to={'/reports'}>
        <img src={LevelsIcon} alt={t('sidebar.reports')} />
        {t('sidebar.reports')}
      </Link>
    ),
    style: panelStyle,
    showArrow: false,
    access: [AccountType.Admin, AccountType.TenantAdmin],
  },
  {
    key: 'users',
    label: (
      <>
        <img src={UserIcon} alt={t('sidebar.users')} />
        {t('sidebar.users')}
      </>
    ),
    children: (
      <>
        <Link
          to={USERS_TENANT_ADMINS_URL.route}
          className={`children-item ${pathname === '/users/tenant_admins' && 'children-item--active'}`}
        >
          {t('sidebar.tenantAdmins')}
        </Link>
        <Link
          to={USERS_TENANT_USERS_OVERVIEW_URL.route}
          className={`children-item ${pathname.includes('tenant_users') && 'children-item--active'}`}
        >
          {t('sidebar.tenantUsers')}
        </Link>
      </>
    ),
    style: panelStyle,
    showArrow: false,
    access: [AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin],
  },
  {
    key: 'purchase',
    label: (
      <>
        <img src={SaleIcon} alt={t('sidebar.purchase')} />
        {t('sidebar.purchase')}
      </>
    ),
    children: (
      <>
        <Link
          to={PURCHASE_OVERVIEW_URL.route}
          className={`children-item ${pathname.includes(PURCHASE_OVERVIEW_URL.route) && 'children-item--active'}`}
        >
          {t('sidebar.overview')}
        </Link>
        <Link
          to={PURCHASE_BALANCE_HISTORY_URL.route}
          className={`children-item ${pathname.includes(PURCHASE_BALANCE_HISTORY_URL.route) && 'children-item--active'}`}
        >
          {t('sidebar.balanceHistory')}
        </Link>
      </>
    ),
    style: panelStyle,
    showArrow: false,
    access: [AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin],
  },
  {
    key: 'bank-transfer',
    className: 'not-collapsible',
    label: (
      <Link to={BANK_TRANSFER_URL.route}>
        <Icon component={BankTransferIcon} />
        {t('sidebar.bankTransfer')}
      </Link>
    ),
    style: panelStyle,
    showArrow: false,
    access: [AccountType.Admin],
  },
];
