import React, { useMemo, useState } from 'react';
import { Steps } from 'antd';
import { getStepItems } from './config';
import { ParkingSessionModel } from 'models/parkingSessionModel';
import { useTranslation } from 'react-i18next';

import './ValidateSessionSteps.scss';

type Props = {
  entry: ParkingSessionModel;
  onConfirm: (duration: number) => void;
};

export function ValidateSessionSteps({ entry, onConfirm }: Props) {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [duration, setDuration] = useState<undefined | number>(entry.hours_validated);

  const stepItem = useMemo(() => {
    return getStepItems(t);
  }, [t]);

  const items = useMemo<{ key: string; title: string }[]>(() => {
    return stepItem.map((item) => ({ key: item.title, title: item.title }));
  }, [stepItem]);

  const onStepChangeHandler = () => {
    if (current + 1 === items.length) {
      onConfirm(duration as number);
      return;
    }

    setCurrent((value) => value + 1);
  };

  const onDurationChangeHandler = (value: number) => {
    setDuration(+value);
  };

  const Component = stepItem[current].content;

  return (
    <div className="validate-session-steps">
      <Steps items={items} current={current} />
      <Component
        onStepChange={onStepChangeHandler}
        duration={duration !== undefined ? (duration > 4 ? 0 : duration) : undefined}
        onDurationChange={onDurationChangeHandler}
        entry={entry}
      />
    </div>
  );
}
