import {
  overview,
  plateNumber,
  customerId,
  mobileNumber,
  startDate,
  endDate,
  search,
  id,
  select,
  confirm,
  hours,
} from './common';

export const validateSessions = {
  overview,
  plateNumber,
  customerId,
  mobileNumber,
  startDate,
  endDate,
  search,
  table: {
    plateNumber,
    id,
    entryTime: 'Entry Time',
    parkingDuration: 'Parking Duration',
    mobileNumber,
    gracePeriod: 'Grace Period',
    validateSession: 'Validate Session',
    validation: 'Validation',
    customerId,
    hours,
    entireSession: 'Entire session',
  },
  modal: {
    selectTheValidationDuration: 'Select The Validation Duration',
    validationDuration: 'Validation Duration',
    oneHoursValidation: 'One Hour Validation (1H)',
    twoHoursValidation: 'Two Hour Validation (2H)',
    threeHoursValidation: 'Three Hour Validation (3H)',
    fourHoursValidation: 'Four Hour Validation (4H)',
    entireSessionValidation: 'Entire Session Validation',
    entireSessionValidationNote:
      'This allows user to have a full day parking session without having to pay the fee',
    select,
    confirmationMessage: 'Confirmation Message',
    confirmMessage: 'Are you sure you want to add the following?',
    duration: 'Duration',
    plateNumber,
    confirm,
  },
  messages: {
    validated: 'Session has been validated successfully!',
  },
  validation: {
    requiredPlateNumber: 'Please enter plate number!',
    requiredCustomerId: 'Please enter plate customer id!',
    requiredMobileNumber: 'Please enter mobile number!',
    requiredDateTime: 'Please enter date and time!',
  },
};
