export const overview = 'ملخص';
export const tenant = 'الوحدة';
export const facility = 'المنشأة';
export const mobileNumber = 'رقم الجوال';
export const email = 'بريد الإلكتروني';
export const search = 'بحث';
export const userEmail = 'بريد الإلكتروني للعميل';
export const userName = 'اسم المستخدم';
export const tenantName = 'اسم الوحدة';
export const action = 'الإجراءات';
export const facilityName = 'اسم المنشأة';
export const date = 'التاريخ';
export const user = 'المستخدم';
export const plateNumber = 'رقم لوحة السيارة';
export const customerId = 'رمز التعريف للعميل';
export const startDate = 'تاريخ البدء';
export const endDate = 'تاريخ الانتهاء';
export const id = 'رمز التعريف';
export const next = 'التالي';
export const prev = 'السابق';
export const select = 'اختيار';
export const confirm = 'تأكيد';
export const hours = '{{value}} ساعات';
export const exportLabel = 'تحميل';
export const type = 'النوع';
export const purchaseHours = 'ساعات الشراء';
export const currentBalance = 'الرصيد الحالي';
