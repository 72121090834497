import React from 'react';

export function EnIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 29">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.75"
        d="M24.813 2.125H4.188c-1.14 0-2.063.923-2.063 2.063v20.625c0 1.139.923 2.062 2.063 2.062h20.625a2.062 2.062 0 0 0 2.062-2.063V4.188a2.062 2.062 0 0 0-2.063-2.062Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.75"
        d="M12.438 9.684h-5.5v9.625h5.156m-5.156-4.813h5.156m3.781-3.437v8.25-4.47a3.094 3.094 0 0 1 6.188 0v4.47"
      />
    </svg>
  );
}
