import { hours } from './common';

export const cardPayment = {
  enterCardDetails: 'إدخل معلومات البطاقة',
  cardNumber: 'رقم البطاقة',
  acceptCreditAndDebitCardTypes: 'Accept credit and debit card types',
  expiryDate: 'تاريخ الإنتهاء',
  month: 'الشهر',
  year: 'السنة',
  paymentSummary: 'ملخص الدفع',
  hoursToBeBought: 'الساعات المراد شراؤها',
  subtotal: 'لمجموع الفرعي',
  vat: 'الضريبة ({{value}}%)',
  total: 'الإجمالي',
  confirmPayment: 'تأكيد الدفع ',
  currentConsumedHours: 'Current consumed hours',
  hours,
  bankAccountInformation: 'Bank Account Information',
  bankName: 'Bank name',
  companyName: 'Company name',
  accountNumber: 'Account number',
  iban: 'IBAN',
  uploadPaymentInvoice: 'Upload Payment Invoice',
  selectFiles: 'Select Files',
};
