import React from 'react';

export function LogoutIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25">
      <path
        fill="currentColor"
        d="M6.25 2.082h9.375a2.083 2.083 0 0 1 2.083 2.083V6.25h-2.083V4.165H6.25v16.667h9.375v-2.083h2.083v2.083a2.083 2.083 0 0 1-2.083 2.083H6.25a2.083 2.083 0 0 1-2.083-2.083V4.165A2.083 2.083 0 0 1 6.25 2.082Z"
      />
      <path
        fill="currentColor"
        d="m16.76 16.24 1.47 1.47 5.207-5.209-5.208-5.208-1.469 1.469 2.688 2.698H9.375v2.083h10.073l-2.688 2.698Z"
      />
    </svg>
  );
}
