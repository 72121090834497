import VisaIcon from 'components/Icons/components/VisaIcon';
import BankTransferIcon from 'components/Icons/components/BankTransferIcon';
import { PaymentMethodType } from 'utils/enums/paymentMethods';

export const paymentMethods = [
  {
    key: PaymentMethodType.VisaPayment,
    name: 'Visa Payment',
    icon: VisaIcon,
  },
  {
    key: PaymentMethodType.BankTransfer,
    name: 'Bank Transfer',
    icon: BankTransferIcon,
  },
];
