import { getLanguages, getSettingsMenuItems, SettingsMenuItemsEnum } from './config';
import { Button, Dropdown, MenuProps } from 'antd';
import React, { createRef, useCallback, useState } from 'react';
import { LanguageEnum } from 'utils/enums/language';
import { useTranslation } from 'react-i18next';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Icon, SettingsIcon } from 'components/Icons';
import { SelectModal } from 'components/SelectModal';

export function Settings() {
  const ref = createRef<HTMLDivElement>();
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onClickHandler: MenuProps['onClick'] = useCallback(({ key }: MenuInfo) => {
    if (key === SettingsMenuItemsEnum.changeLanguage) {
      showModal();
    }
  }, []);

  const onChangeLanguageHandler = useCallback(
    (language: LanguageEnum) => {
      i18n.changeLanguage(language);
    },
    [i18n],
  );

  return (
    <>
      <div ref={ref} className="dropdown-popup-container">
        <SelectModal
          title={t('header.chooseLanguage')}
          open={isModalOpen}
          onCancel={handleCancel}
          list={getLanguages(t)}
          onSelect={onChangeLanguageHandler}
        />
      </div>
      <Dropdown
        menu={{ items: getSettingsMenuItems(t), onClick: onClickHandler }}
        trigger={['click']}
        dropdownRender={(menu) => (
          <div className="settings-dropdown-popup">
            <h4>{t('header.settings')}</h4>
            {menu}
          </div>
        )}
        align={{ offset: [-140, 14] }}
        getPopupContainer={() => ref.current as HTMLDivElement}
      >
        <Button type="default" shape="circle" icon={<Icon component={SettingsIcon} />} />
      </Dropdown>
    </>
  );
}
