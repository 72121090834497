import { NavigateToHome } from 'components/Routes/NavigateToHome';
import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { AccountType } from 'models/user.model';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser, getCurrentUserIsLoading } from 'store/user/selectors';
import { Spin } from 'antd';

type Props = PropsWithChildren<{
  roles?: AccountType[];
  redirectTarget?: string;
}>;

export function RoleAccess({ children, roles = [], redirectTarget }: Props) {
  const user = useAppSelector(getCurrentUser);
  const isUserLoading = useAppSelector(getCurrentUserIsLoading);

  if (!user || isUserLoading) {
    return <Spin />;
  }

  return !roles.length || roles.includes(user?.account_type) ? (
    <>{children}</>
  ) : redirectTarget ? (
    <Navigate to={redirectTarget} replace />
  ) : (
    <NavigateToHome />
  );
}
