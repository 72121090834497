import {
  overview,
  facility,
  tenant,
  email,
  mobileNumber,
  search,
  userEmail,
  userName,
  tenantName,
  action,
} from './common';

export const tenantUsers = {
  tabs: {
    overview,
    disabledTenantUsers: 'حسابات مستخدمين الوحدة المعطلة',
    addTenantUser: 'إضافة مستخدم للوحدة',
  },
  search: {
    facility,
    tenant,
    email,
    mobileNumber,
    button: search,
  },
  table: {
    userEmail,
    userName,
    facility,
    tenantName,
    mobileNumber,
    action,
  },
  form: {
    usernameAndPasswordSection: 'اسم المستخدم و كلمة المرور',
    accountInformationSection: 'معلومات الحساب ',
    assignTenantSection: 'تعيين الوحدة ',
    validationPermissionSection: 'تصريح لاعتماد رسوم المواقف',
    username: 'اسم المستخدم (حساب البريد الإلكتروني)',
    password: 'كلمة المرور',
    confirmPassword: 'تأكيد كلمة المرور',
    button: 'إنشاء حساب ',
    accountName: 'اسم الحساب ',
    mobileNumber,
    phone: 'الهاتف الأرضي ',
    description: 'الوصف',
    contactPerson: 'جهة الاتصال (اختياري)',
    maximumAllowedValidationHours: 'الحد الأقصى لساعات التحقق المسموح بها',
    allowEntireSessionValidation: 'السماح من اعتماد المواقف دون رسوم ',
    allowEntireSessionValidationNote:
      'يتيح ذلك للمستخدم الحصول على فرصة لركن السيارة ليوم كامل دون الحاجة إلى دفع الرسوم',
    updateAccount: 'تحديث الحساب',
  },
  message: {
    activated: 'تم تفعيل مستخدم الوحدة بنجاح!',
    deactivated: 'تم إلغاء تنشيط مستخدم الوحدة بنجاح!',
    created: 'تم إنشاء مستخدم الوحدة بنجاح!',
    updated: 'تم تحديث مستخدم الوحدة بنجاح!',
  },
  confirm: {
    enableUser: 'هل تريد تمكين المستخدم؟',
    disable: 'هل تريد تعطيل المستخدم؟',
    ok: 'نعم',
    cancel: 'إلغاء',
  },
  validation: {
    requiredUserName: 'الرجاء إدخال اسم المستخدم الخاص بك!',
    emailUserName: 'اسم المستخدم ليس بريدًا إلكترونيًا صالحًا!',
    maxUserName: 'يجب أن يصل اسم المستخدم إلى ${max} من الأحرف!',
    requiredPassword: 'الرجاء إدخال كلمة المرور الخاصة بك!',
    minPassword: 'يجب أن تتكون كلمة المرور من أحرف ${min} على الأقل!',
    containPassword:
      'يجب أن تحتوي كلمة المرور على رقم واحد على الأقل وحرف خاص واحد وحرف كبير وصغير واحد، وعلى الأقل {{min}} أو أكثر من الأحرف!',
    confirmPassword: 'كلمة المرور المؤكدة التي أدخلتها غير متطابقة!',
    requiredAccountName: 'الرجاء إدخال اسم حسابك!',
    maxAccountName: 'يجب أن يصل اسم الحساب إلى ${max} من الأحرف!',
    firstNameLastName: 'يجب أن يكون اسم الحساب هو الاسم الأول والأخير للمستخدم!',
    requiredPhone: 'الرجاء إدخال الهاتف!',
    maxPhone: 'يجب أن يصل رقم الهاتف إلى ${max} من الأرقام!',
    starWithPhone: 'يجب أن يبدأ ${label} بالرقم 012، +12!',
    requiredPhoneCode: 'الرجاء إدخال رمز الجوال الخاص بك!',
    requiredMobile: 'الرجاء إدخال رقم الجوال!',
    requiredDescription: 'الرجاء إدخال الوصف الخاص بك!',
    maxContact: 'يجب أن يصل عدد الأرقام لجهة الاتصال إلى ${max} من الأرقام!',
    tenantRequired: 'الرجاء اختيار اسم الوحدة الخاص بك!',
    requiredMaximumAllowedValidationHours: 'يرجى إدخال الحد الأقصى لساعات الاعتماد المسموح بها!',
    incorrectValue: 'قيمة غير صحيحة!',
  },
};
