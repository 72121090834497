import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { Spin } from 'antd';
import React, { PropsWithChildren, ReactElement } from 'react';
import { AccountType } from 'models/user.model';
import { hasRole } from 'utils/permission/role';
import { wrap } from 'utils/array/helper';

type GuardProps = PropsWithChildren<{
  roles: AccountType | AccountType[];
}>;

export function Guard({ roles, children }: GuardProps) {
  const user = useAppSelector(getCurrentUser);

  if (!user) {
    return <Spin />;
  }

  if (hasRole(user.account_type, wrap(roles))) {
    return children as ReactElement;
  }

  return null;
}
