import { Menu } from 'components/Layout/SideBar/components/Menu';
import { Logo } from 'components/Logo';
import React from 'react';

import './SideBar.scss';

export const SideBar = () => {
  return (
    <div className="sidebar">
      <div className="logo-container">
        <Logo />
      </div>

      <Menu />
    </div>
  );
};
