import { TFunction } from 'i18next';
import { TableProps } from 'antd';
import moment from 'moment/moment';
import { BalanceHistoryType } from 'utils/enums/balanceHistoryType';

export function getColumns(t: TFunction): TableProps['columns'] {
  return [
    {
      dataIndex: 'index',
      title: '#',
      width: '3%',
      render(value, entry, index) {
        return ('0' + (index + 1)).slice(-2);
      },
    },
    {
      dataIndex: 'date',
      title: t('balanceHistory.table.date'),
      render(value) {
        if (!value) {
          return;
        }
        return moment(value).format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      dataIndex: 'plate_number',
      title: t('balanceHistory.table.plateNumber'),
    },
    {
      dataIndex: 'username',
      title: t('balanceHistory.table.userName'),
    },
    {
      dataIndex: 'type',
      title: t('balanceHistory.table.type'),
      render(value: BalanceHistoryType) {
        return t(`balanceHistory.type.${value}`);
      },
    },
    {
      dataIndex: 'total_hours',
      title: t('balanceHistory.table.hours'),
      render(value, { type }) {
        if (type === BalanceHistoryType.Validation && value === 0) {
          return t('validateSessions.table.entireSession');
        }

        return value;
      },
    },
    {
      dataIndex: 'new_value',
      title: t('balanceHistory.table.newValue'),
      render(value) {
        return value || '-';
      },
    },
    {
      dataIndex: 'current_balance',
      title: t('balanceHistory.table.currentBalance'),
    },
  ];
}
