import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, DatePicker, Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FullWidthButton } from 'components/Button/FullWidthButton';
import { Dayjs } from 'dayjs';
import { CalendarIcon, ClockIcon, CustomerIcon, Icon, TypeIcon } from 'components/Icons';
import { useTranslation } from 'react-i18next';
import { ShopOutlined, TeamOutlined, WalletOutlined } from '@ant-design/icons';
import { FacilityModel } from 'models/facility.model';
import { TenantModel } from 'models/tenant.model';
import { Guard } from 'components/Guard';
import { AccountType } from 'models/user.model';
import { BalanceHistoryType } from 'utils/enums/balanceHistoryType';
import { hasRole } from 'utils/permission/role';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { useTenantUsers } from 'hooks';

export type FormValues = {
  facility_id?: number;
  tenant_id?: number;
  user_id?: number;
  type?: string;
  purchased_hours?: number;
  current_balance?: number;
  date?: Dayjs[];
};

type BalanceHistorySearchFormProps = {
  facilities?: FacilityModel[];
  tenants?: TenantModel[];
  onFormSubmit: (params: SearchParams) => void;
};

type SearchParams = Omit<FormValues, 'date'> & { date_from?: string; date_to?: string };

export function BalanceHistorySearchForm({
  onFormSubmit,
  tenants = [],
  facilities = [],
}: BalanceHistorySearchFormProps) {
  const { t } = useTranslation();
  const [form] = useForm();
  const user = useAppSelector(getCurrentUser);
  const [filteredTenants, setFilteredTenants] = useState<TenantModel[]>([]);
  const tenantUsers = useTenantUsers(user);

  const onChangeFacilityIdHandler = useCallback(
    (value: number) => {
      form.resetFields(['tenant_id']);
      setFilteredTenants(tenants?.filter(({ facility_id }) => facility_id === value));
    },
    [tenants],
  );

  const onFormSubmitHandler = ({ date, ...rest }: FormValues) => {
    const newParams: SearchParams = {
      ...rest,
    };

    if (date?.[0]) {
      newParams.date_from = date[0].format('YYYY-MM-DD HH:mm:ss');
    }

    if (date?.[1]) {
      newParams.date_to = date[1].format('YYYY-MM-DD HH:mm:ss');
    }

    onFormSubmit(newParams);
  };

  useEffect(() => {
    if (hasRole(user?.account_type, [AccountType.FacilityOwner])) {
      const facilityId = facilities?.[0]?.facility_id;

      if (facilityId) {
        form.setFieldsValue({
          facility_id: facilityId,
        });
        onChangeFacilityIdHandler(facilityId);
      }
    }
  }, [user?.account_type, facilities, tenants]);

  return (
    <Form<FormValues>
      className="balance-history-search-form"
      form={form}
      layout="inline"
      onFinish={onFormSubmitHandler}
    >
      <Guard roles={[AccountType.Admin, AccountType.FacilityOwner]}>
        <Form.Item
          name="facility_id"
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            placeholder={
              <div className="select-icon">
                <ShopOutlined /> {t('balanceHistory.form.facility')}
              </div>
            }
            onChange={onChangeFacilityIdHandler}
            allowClear
          >
            {facilities.map(({ facility_id, facility_name }) => (
              <Select.Option key={facility_id} value={facility_id}>
                {facility_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="tenant_id" rules={[{ required: true, message: t('validation.required') }]}>
          <Select
            placeholder={
              <div className="select-icon">
                <TeamOutlined /> {t('balanceHistory.form.tenant')}
              </div>
            }
            disabled={filteredTenants.length === 0}
            allowClear
          >
            {filteredTenants.map(({ tenant_id, tenant_name }) => (
              <Select.Option key={tenant_id} value={tenant_id}>
                {tenant_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Guard>
      <Form.Item name="date">
        <DatePicker.RangePicker
          placement="bottomRight"
          suffixIcon={<Icon component={CalendarIcon} />}
          allowClear
          placeholder={[t('balanceHistory.form.startDate'), t('balanceHistory.form.endDate')]}
        />
      </Form.Item>

      <Form.Item name="user_id">
        <Select
          placeholder={
            <div className="select-icon">
              <Icon component={CustomerIcon} /> {t('report.form.tenantUser')}
            </div>
          }
          allowClear
        >
          {tenantUsers.map(({ user_id, display_name }) => (
            <Select.Option key={user_id} value={user_id}>
              {display_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="type">
        <Select
          placeholder={
            <div className="select-icon">
              <Icon component={TypeIcon} /> {t('balanceHistory.form.type')}
            </div>
          }
          allowClear
        >
          <Select.Option value={BalanceHistoryType.Purchase}>
            {t('balanceHistory.type.purchase')}
          </Select.Option>
          <Select.Option value={BalanceHistoryType.Validation}>
            {t('balanceHistory.type.validation')}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="purchased_hours">
        <Input
          prefix={<Icon component={ClockIcon} />}
          allowClear
          placeholder={t('balanceHistory.form.purchaseHours')}
        />
      </Form.Item>

      <Form.Item name="current_balance">
        <Input
          prefix={<WalletOutlined />}
          allowClear
          placeholder={t('balanceHistory.form.currentBalance')}
        />
      </Form.Item>

      <Form.Item name="showArchived" valuePropName="checked">
        <Checkbox>Show archived recordings</Checkbox>
      </Form.Item>

      <FullWidthButton className="submit-button" htmlType="submit" type="primary">
        {t('balanceHistory.form.search')}
      </FullWidthButton>
    </Form>
  );
}
