import { hours } from './common';

export const cardPayment = {
  enterCardDetails: 'Enter Card Details',
  cardNumber: 'Card number',
  acceptCreditAndDebitCardTypes: 'Accept credit and debit card types',
  expiryDate: 'Expiry date',
  month: 'Month',
  year: 'Year',
  paymentSummary: 'Payment Summary',
  hoursToBeBought: 'Hours to be bought',
  subtotal: 'Subtotal',
  vat: 'VAT ({{value}}%)',
  total: 'Total',
  confirmPayment: 'Confirm Payment',
  currentConsumedHours: 'Current consumed hours',
  hours,
  bankAccountInformation: 'Bank Account Information',
  bankName: 'Bank name',
  companyName: 'Company name',
  accountNumber: 'Account number',
  iban: 'IBAN',
  uploadPaymentInvoice: 'Upload Payment Invoice',
  selectFiles: 'Select Files',
};
