import { Button, Divider } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { ReportsSearchForm } from './components/ReportsSearchForm';
import { ReportsTable } from './components/ReportsTable';
import { ReportFileTypeModal } from './components/ReportFileTypeModal';
import { Content } from 'components/Content';
import { defaultPagination, PaginationModel } from 'models/paginationModel';
import { useFacilities, useTenants, useTenantUsers } from 'hooks';
import reportsService from 'services/reports.service';
import { ReportModel } from 'models/report.model';
import { FormValues } from './components/ReportsSearchForm';
import { getInitialValuesForSearchForm } from 'utils/form/initialValues';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { useTranslation } from 'react-i18next';

import './Reports.scss';
import DownloadIcon from 'assets/images/download-icon.svg';

export type SearchFormValues = {
  facility_id?: number;
  tenant_id?: number;
  tenant_user_id?: number;
  customer_id?: number;
  customer_mobile?: number;
  date_from?: string;
  date_to?: string;
};

export const Reports = () => {
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ReportModel[]>();
  const [pagination, setPagination] = useState<PaginationModel>(defaultPagination);
  const tenants = useTenants();
  const facilities = useFacilities();
  const tenantUsers = useTenantUsers(user);
  const [openModal, setOpenModal] = useState<boolean>();
  const [searchParams, setSearchParams] = useState<SearchFormValues>({});

  const loadData = useCallback((params: SearchFormValues & { page?: number; limit?: number }) => {
    setLoading(true);
    reportsService
      .getReports({
        page: 1,
        limit: 20,
        ...params,
      })
      .then(({ data: { items, meta } }) => {
        setData(items);
        setPagination(meta);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onFormSubmitHandler = useCallback((params: SearchFormValues) => {
    setSearchParams(params);
    loadData(params);
  }, []);

  const onChangePaginationHandler = useCallback(
    (page: number, pageSize: number) => {
      loadData({ ...searchParams, page, limit: pageSize });
    },
    [searchParams],
  );

  const initialValues = useMemo<FormValues>(() => {
    return getInitialValuesForSearchForm(user, tenants, facilities) as FormValues;
  }, [user?.tenant_id, user?.account_type, facilities, tenants]);

  return (
    <Content className="tenant-admins-overview-content">
      <ReportsSearchForm
        onFormSubmit={onFormSubmitHandler}
        tenants={tenants}
        facilities={facilities}
        tenantUsers={tenantUsers}
        initialValues={initialValues}
      />

      {(loading || data) && (
        <>
          <Divider />
          <div className="reports-export-button">
            <Button
              type="primary"
              icon={<img src={DownloadIcon} alt="export" />}
              onClick={() => setOpenModal(true)}
            >
              {t('report.table.export')}
            </Button>
          </div>

          <ReportsTable
            data={data}
            loading={loading}
            onChangePagination={onChangePaginationHandler}
            pagination={pagination}
          />
          <ReportFileTypeModal
            open={openModal}
            setOpen={setOpenModal}
            searchParams={searchParams}
          />
        </>
      )}
    </Content>
  );
};
