import React, { PropsWithChildren } from 'react';
import { Typography } from 'antd';

import './Section.scss';
import classNames from 'classnames';

type Props = PropsWithChildren<{
  title?: string;
  className?: string;
}>;

export function Section({ title, children, className }: Props) {
  return (
    <div className={classNames('section', className)}>
      {title && (
        <Typography.Title level={4} className="section-title">
          {title}
        </Typography.Title>
      )}

      {children}
    </div>
  );
}
