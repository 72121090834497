import React from 'react';
import { Button, Space } from 'antd';
import { ParkingSessionModel } from 'models/parkingSessionModel';
import { IconButton } from 'components/Button/IconButton';
import editIcon from 'assets/images/edit-icon.svg';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { canEditParkingValidation } from 'utils/policy/parlingSession';

import './ActionColumn.scss';
import { useTranslation } from 'react-i18next';

type ActionColumnProps = {
  onClick: (entry: ParkingSessionModel) => void;
  model: ParkingSessionModel;
};

export function ActionColumn({ onClick, model }: ActionColumnProps) {
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);

  const onClickHandler = () => {
    onClick(model);
  };

  if (model.hours_validated === null) {
    return (
      <Button
        type="default"
        className="validate-session-table-action-button"
        onClick={onClickHandler}
      >
        {t('validateSessions.table.validation')}
      </Button>
    );
  }

  const span = (
    <span className="hours-validated">
      {model.hours_validated === 0
        ? t('validateSessions.table.entireSession')
        : t('validateSessions.table.hours', { value: model.hours_validated })}
    </span>
  );

  if (!canEditParkingValidation(model, user)) {
    return span;
  }

  return (
    <Space>
      {span}
      <IconButton
        icon={editIcon}
        className="validate-session-table-action-icon-button"
        onClick={onClickHandler}
      />
    </Space>
  );
}
