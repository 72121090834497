import React from 'react';
import { Divider, Radio, Space, Typography } from 'antd';

import './TenantRadioGroup.scss';
import { TenantModel } from 'models/tenant.model';

type Props = {
  title: string;
  tenants?: TenantModel[];
};

const { Title } = Typography;

export function TenantRadioGroup({ title, tenants = [] }: Props) {
  return (
    <div className="tenant-radio-group">
      <Title level={5} className="tenant-radio-group-title">
        {title}
      </Title>
      <Divider />
      <Space direction="vertical">
        {tenants.map(({ tenant_id, tenant_name }) => (
          <Radio value={tenant_id} key={tenant_id}>
            {tenant_name}
          </Radio>
        ))}
      </Space>
    </div>
  );
}
