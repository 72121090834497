import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './index.scss';

import 'lang';

import moment from 'moment-timezone';
moment.tz.setDefault('Asia/Dubai');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
