import { Table } from 'antd';
import { UserModel } from 'models/user.model';
import { getColumns } from './columns';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  users: UserModel[];
};

export const TenantAdminsTable = ({ users }: Props) => {
  const { t } = useTranslation();

  return (
    <Table
      bordered
      rowKey="index"
      className="table"
      columns={getColumns(t)}
      dataSource={users as never}
      pagination={false}
      locale={{ emptyText: t('table.emptyText') }}
    />
  );
};
