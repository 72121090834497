import { Spin } from 'antd';
import { useAppSelector } from 'hooks/useAppSelector';
import { AccountType } from 'models/user.model';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getCurrentUser, getCurrentUserIsLoading } from 'store/user/selectors';
import { USERS_TENANT_USERS_OVERVIEW_URL, VALIDATE_SESSIONS_URL } from 'utils/constants/clientUrls';

export const homeUrl: Record<AccountType, string> = {
  [AccountType.TenantUser]: VALIDATE_SESSIONS_URL.route,
  [AccountType.TenantAdmin]: VALIDATE_SESSIONS_URL.route,
  [AccountType.Admin]: USERS_TENANT_USERS_OVERVIEW_URL.route,
  [AccountType.FacilityOwner]: USERS_TENANT_USERS_OVERVIEW_URL.route,
};

export const NavigateToHome = () => {
  const user = useAppSelector(getCurrentUser);
  const isUserLoading = useAppSelector(getCurrentUserIsLoading);

  return !user || isUserLoading ? <Spin /> : <Navigate to={homeUrl[user?.account_type]} replace />;
};
