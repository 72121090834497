import React, { useEffect, useState } from 'react';
import { Content } from 'components/Content';
import { ValidationTypeFactory } from './components/ValidationTypeFactory';
import { PurchaseOverviewSearchForm } from './components/PurchaseOverviewSearchForm';
import { tenantsService } from 'services/tenants.service';
import { Divider, Flex, Spin } from 'antd';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { TenantDetailsModel, TenantModel } from 'models/tenant.model';
import { useTranslation } from 'react-i18next';
import { Guard } from 'components/Guard';
import { AccountType } from 'models/user.model';
import { hasRole } from 'utils/permission/role';
import { FacilityModel } from 'models/facility.model';
import { facilitiesService } from 'services/facility.service';

import './Overview.scss';

export function Overview() {
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);
  const [loaded, setLoaded] = useState(true);
  const [tenant, setTenant] = useState<null | TenantDetailsModel>(null);
  const [facilities, setFacilities] = useState<FacilityModel[]>();
  const [tenants, setTenants] = useState<TenantModel[]>();

  function loadPurchaseData(tenantId: number) {
    setLoaded(false);
    tenantsService
      .getTenantDetails(tenantId)
      .then(({ data }) => setTenant(data))
      .finally(() => {
        setLoaded(true);
      });
  }

  useEffect(() => {
    if (!hasRole(user?.account_type, [AccountType.Admin, AccountType.FacilityOwner])) {
      loadPurchaseData(user?.tenant_id);
      return;
    }

    facilitiesService.getFacilities().then(({ data }) => setFacilities(data));
    tenantsService.getTenants().then(({ data }) => setTenants(data));
  }, [user?.tenant_id]);

  return (
    <Content className="purchase-overview">
      <Guard roles={[AccountType.FacilityOwner, AccountType.Admin]}>
        <PurchaseOverviewSearchForm
          facilities={facilities}
          tenants={tenants}
          onLoad={loadPurchaseData}
        />
        {tenant && <Divider />}
      </Guard>
      {!loaded && (
        <Flex gap="small" vertical>
          <Spin />
        </Flex>
      )}
      {tenant && (
        <ValidationTypeFactory
          rate={{ rate: tenant.hourly_rate, currency: t('currency.sar') }}
          tenant={tenant}
        />
      )}
    </Content>
  );
}
