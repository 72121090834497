export enum AccountType {
  Admin,
  FacilityOwner,
  TenantAdmin,
  TenantUser,
}

export interface UserModel {
  index: number;
  user_id: number;
  username: string;
  tenant_id: number;
  email: string;
  mobile: string;
  facility: string;
  display_name: string;
  account_type: AccountType;
  max_allowed_validation_hours?: number;
  allow_entire_session_validation?: boolean;
}
