import React, { useCallback } from 'react';
import { Form, Select } from 'antd';
import { ShopOutlined, TeamOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FullWidthButton } from 'components/Button/FullWidthButton';
import { useSearchFormData } from './hooks/useSearchFormData';

import './BankTransferSearchForm.scss';

type BankTransferSearchFormProps = {
  onLoad: (tenantId: number) => void;
};

type FormData = {
  facility_id: number;
  tenant_id: number;
};

export function BankTransferSearchForm({ onLoad }: BankTransferSearchFormProps) {
  const { t } = useTranslation();
  const [form, facilities, tenants, onChangeFacilityIdHandler] = useSearchFormData<FormData>();

  const onFinishHandler = useCallback((formData: FormData) => {
    onLoad(formData.tenant_id);
  }, []);

  return (
    <Form
      form={form}
      className="bank-transfer-search-form"
      layout="inline"
      onFinish={onFinishHandler}
    >
      <Form.Item name="facility_id" rules={[{ required: true, message: t('validation.required') }]}>
        <Select
          placeholder={
            <div className="select-icon">
              <ShopOutlined /> {t('bankTransfer.form.facility')}
            </div>
          }
          onChange={onChangeFacilityIdHandler}
          allowClear
        >
          {facilities.map(({ facility_id, facility_name }) => (
            <Select.Option key={facility_id} value={facility_id}>
              {facility_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="tenant_id" rules={[{ required: true, message: t('validation.required') }]}>
        <Select
          placeholder={
            <div className="select-icon">
              <TeamOutlined /> {t('bankTransfer.form.tenant')}
            </div>
          }
          disabled={tenants.length === 0}
          allowClear
        >
          {tenants.map(({ tenant_id, tenant_name }) => (
            <Select.Option key={tenant_id} value={tenant_id}>
              {tenant_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <FullWidthButton className="submit-button" htmlType="submit" type="primary">
        {t('purchase.form.search')}
      </FullWidthButton>
    </Form>
  );
}
