import { NavigateToHome } from 'components/Routes/NavigateToHome';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { PrivateRoute } from 'components/Routes/PrivateRoute';
import { PublicRoute } from 'components/Routes/PublicRoute';
import { Reports } from 'features/Reports';
import { Login } from 'features/Login';
import { TenantAdmins } from 'features/Users/components/TenantAdmins';
import { AddUser } from 'features/Users/components/TenantUsers/components/AddUser';
import { EditUser } from 'features/Users/components/TenantUsers/components/EditUser';
import { DisabledUsers } from 'features/Users/components/TenantUsers/components/DisabledUsers';
import { Overview } from 'features/Users/components/TenantUsers/components/Overview';
import { ValidateSessions } from 'features/ValidateSessions';
import { TenantUsers } from 'features/Users/components/TenantUsers';
import { Overview as PurchaseOverview } from 'features/Purchase/components/Overview';
import { BalanceHistory } from 'features/Purchase/components/BalanceHistory';
import { Checkout } from 'features/Purchase/components/Checkout';
import { Layout } from 'components/Layout';
import {
  BANK_TRANSFER_URL,
  HOURS_BALANCE_URL,
  LOGIN_URL,
  PURCHASE_BALANCE_HISTORY_URL,
  PURCHASE_OVERVIEW_URL,
  PURCHASE_PAYMENT_CHECKOUT_URL,
  PURCHASE_URL,
  REPORTS_URL,
  USERS_TENANT_ADMINS_URL,
  USERS_TENANT_USERS_ADD_TENANT_USER_URL,
  USERS_TENANT_USERS_DISABLED_TENANT_USERS_URL,
  USERS_TENANT_USERS_EDIT_TENANT_USER_URL,
  USERS_TENANT_USERS_OVERVIEW_URL,
  USERS_TENANT_USERS_URL,
  USERS_URL,
  VALIDATE_SESSIONS_URL,
} from 'utils/constants/clientUrls';
import { RoleAccess } from 'components/RoleAccess';
import { AccountType } from 'models/user.model';
import { BankTransfer } from 'features/BankTransfer/BankTransfer';

export const router = createBrowserRouter([
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '*',
        element: <NavigateToHome />,
      },
      {
        path: VALIDATE_SESSIONS_URL.route,
        element: (
          <RoleAccess roles={[AccountType.TenantAdmin, AccountType.TenantUser]}>
            <Layout>
              <ValidateSessions />
            </Layout>
          </RoleAccess>
        ),
      },
      {
        path: HOURS_BALANCE_URL.route,
        element: (
          <RoleAccess
            roles={[AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin]}
          >
            <Layout>Hours Balance</Layout>
          </RoleAccess>
        ),
      },
      {
        path: USERS_URL.route,
        children: [
          {
            path: USERS_TENANT_ADMINS_URL.route,
            element: (
              <RoleAccess
                roles={[AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin]}
              >
                <Layout>
                  <TenantAdmins />
                </Layout>
              </RoleAccess>
            ),
          },
          {
            path: USERS_TENANT_USERS_URL.route,
            children: [
              {
                path: USERS_TENANT_USERS_OVERVIEW_URL.route,
                element: (
                  <RoleAccess
                    roles={[AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin]}
                  >
                    <Layout>
                      <TenantUsers>
                        <Overview />
                      </TenantUsers>
                    </Layout>
                  </RoleAccess>
                ),
              },
              {
                path: USERS_TENANT_USERS_DISABLED_TENANT_USERS_URL.route,
                element: (
                  <RoleAccess
                    roles={[AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin]}
                  >
                    <Layout>
                      <TenantUsers>
                        <DisabledUsers />
                      </TenantUsers>
                    </Layout>
                  </RoleAccess>
                ),
              },
              {
                path: USERS_TENANT_USERS_ADD_TENANT_USER_URL.route,
                element: (
                  <RoleAccess
                    roles={[AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin]}
                  >
                    <Layout>
                      <TenantUsers>
                        <AddUser />
                      </TenantUsers>
                    </Layout>
                  </RoleAccess>
                ),
              },
              {
                path: USERS_TENANT_USERS_EDIT_TENANT_USER_URL.route,
                element: (
                  <RoleAccess
                    roles={[AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin]}
                  >
                    <Layout>
                      <TenantUsers>
                        <EditUser />
                      </TenantUsers>
                    </Layout>
                  </RoleAccess>
                ),
              },
            ],
          },
        ],
      },
      {
        path: REPORTS_URL.route,
        element: (
          <RoleAccess roles={[AccountType.Admin, AccountType.TenantAdmin]}>
            <Layout>
              <Reports />
            </Layout>
          </RoleAccess>
        ),
      },
      {
        path: PURCHASE_URL.route,
        children: [
          {
            path: PURCHASE_OVERVIEW_URL.route,
            element: (
              <RoleAccess
                roles={[AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin]}
              >
                <Layout>
                  <PurchaseOverview />
                </Layout>
              </RoleAccess>
            ),
          },
          {
            path: PURCHASE_PAYMENT_CHECKOUT_URL.route,
            element: (
              <RoleAccess roles={[AccountType.TenantAdmin]}>
                <Checkout />
              </RoleAccess>
            ),
          },
          {
            path: PURCHASE_BALANCE_HISTORY_URL.route,
            element: (
              <RoleAccess
                roles={[AccountType.Admin, AccountType.FacilityOwner, AccountType.TenantAdmin]}
              >
                <Layout>
                  <BalanceHistory />
                </Layout>
              </RoleAccess>
            ),
          },
        ],
      },
      {
        path: BANK_TRANSFER_URL.route,
        element: (
          <RoleAccess>
            <Layout>
              <BankTransfer />
            </Layout>
          </RoleAccess>
        ),
      },
    ],
  },
  { element: <PublicRoute />, children: [{ path: LOGIN_URL.route, element: <Login /> }] },
]);
