import { api } from 'lib/api';
import { ParkingSessionModel } from 'models/parkingSessionModel';
import { PaginationModel } from 'models/paginationModel';

type ParkingSessionParameters = NonNullable<unknown>;

const getParkingSession = (params: ParkingSessionParameters = {}) =>
  api.get<{
    items: ParkingSessionModel[];
    meta: PaginationModel;
  }>('/parking-sessions', { params });

type EditParkingSessionData = {
  validation_duration?: number;
  entire_session?: boolean;
};

const editParkingSession = (id: number, data: EditParkingSessionData) =>
  api.patch(`/parking-sessions/${id}/validate`, data);

export default {
  getParkingSession,
  editParkingSession,
};
