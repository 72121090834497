import { ColumnType } from 'antd/lib/table/interface';

export function generateTableReport<T>(
  columns: ColumnType<T>[],
  data: T[],
  filename: string = 'Report',
) {
  const head = columns.map((c) => c.title);
  const body = data.map((r, index) =>
    columns.map((c) => {
      const value = r[c.dataIndex as keyof T];

      return c.render ? c.render(value, r, index) : value;
    }),
  );

  const innerHtml =
    '<table>' +
    '<thead><tr>' +
    head.map((value) => `<th>${value}</th>`).join('') +
    '</tr></thead>' +
    '<tbody>' +
    body.map((row) => `<tr>${row.map((cell) => `<td>${cell}</td>`).join('')}</tr>`).join('') +
    '</tbody>' +
    '</table>';

  const excelTemplate =
    '<html> ' +
    '<head> ' +
    '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
    '</head> ' +
    '<body> ' +
    innerHtml +
    '</body> ' +
    '</html>';

  const link = document.createElement('a');
  const file = new Blob([excelTemplate], { type: 'data:application/vnd.ms-excel;base64,' });
  link.href = URL.createObjectURL(file);
  link.download = `${filename}.xls`;
  link.click();
  URL.revokeObjectURL(link.href);
}
