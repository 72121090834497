import {
  email,
  facility,
  overview,
  search,
  tenant,
  mobileNumber,
  userEmail,
  userName,
  tenantName,
} from './common';

export const tenantAdmins = {
  overview,
  search: {
    facility,
    tenant,
    email,
    mobileNumber,
    button: search,
  },
  table: {
    userEmail,
    userName,
    facility,
    tenantName,
    mobileNumber,
  },
};
