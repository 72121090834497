import { UserModel } from 'models/user.model';
import React, { useEffect, useMemo, useState } from 'react';
import { FormValues, TenantAdminsSearchForm } from './components/TenantAdminsSearchForm';
import { TenantAdminsTable } from './components/TenantAdminsTable';
import { usersService } from 'services/users.service';
import { getInitialValuesForSearchForm } from 'utils/form/initialValues';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { Content } from 'components/Content';
import { useFacilities, useTenants } from 'hooks';
import { useTranslation } from 'react-i18next';

import './TenantAdmins.scss';

export const TenantAdmins = () => {
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);
  const [users, setUsers] = useState<UserModel[]>([]);
  const tenants = useTenants();
  const facilities = useFacilities();

  useEffect(() => {
    usersService.getTenantAdmins({}).then(({ data }) => setUsers(data));
  }, []);

  const initialValues = useMemo<FormValues>(() => {
    return getInitialValuesForSearchForm(user, tenants, facilities) as FormValues;
  }, [user?.tenant_id, user?.account_type, facilities, tenants]);

  return (
    <Content title={t('tenantAdmins.overview')} className="tenant-admins-overview-content">
      <TenantAdminsSearchForm
        facilities={facilities}
        setUsers={setUsers}
        tenants={tenants}
        initialValues={initialValues as FormValues}
      />
      <TenantAdminsTable users={users} />
    </Content>
  );
};
