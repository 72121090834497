import {
  date,
  facilityName,
  tenantName,
  user,
  hours,
  purchaseHours,
  facility,
  search,
  tenant,
} from './common';
import { ValidationType } from 'models/tenant.model';

export const purchase = {
  tenantDetails: 'تفاصيل الوحدة ',
  facilityName,
  tenantName,
  validationType: 'نوع رسوم الاعتماد',
  prePaidInformation: 'معلومات مسبقة الدفع',
  availableHours: 'الساعات المتاحة',
  totalBoughtHours: 'إجمالي ساعات الشراء',
  totalConsumedHours: 'إجمالي الساعات المستهلكة',
  postPaidInformation: 'معلومات ما بعد الدفع',
  validationLimit: 'الحد الأقصى لساعات المعتمدة',
  currentConsumedHours: 'الساعات المستهلكة الحالية',
  dueAmount: 'المبلغ المستحق',
  hours,
  payNow: 'ادفع الآن',
  tenantPurchaseHistory: 'تاريخ شراء الوحدة ',
  date,
  user,
  totalHours: 'مجموع الساعات',
  paidAmount: 'المبلغ المدفوع',
  validationTypeLabels: {
    [ValidationType.PrePaid]: 'مسبقة الدفع',
    [ValidationType.PostPaid]: 'بعد الدفع',
  },
  hoursToBePurchased: 'الساعات التي سيتم شراؤها',
  totalAmount: 'المبلغ الإجمالي',
  purchaseHours,
  purchaseHoursNote:
    'كل ساعة تضرب في {{rate}}. مثال: {{amount}} (الساعة) × {{rate}} (سعر الساعة) = {{result}} ريال',
  message: {
    successPayment: 'تم الدفع بنجاح!',
  },
  form: {
    facility,
    tenant,
    search,
  },
  modal: {
    selectPaymentMethod: 'Select Type Of Payment Method',
  },
};
