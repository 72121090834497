import React from 'react';
import { Section } from 'components/Section';
import { DetailsGrid } from 'components/DetailsGrid';
import { getColumns } from './config';
import { TenantDetailsModel } from 'models/tenant.model';
import { useTranslation } from 'react-i18next';

type TenantDetailsProps = {
  model: TenantDetailsModel;
};

export function TenantDetailsSection({ model }: TenantDetailsProps) {
  const { t } = useTranslation();

  return (
    <Section title={t('purchase.tenantDetails')}>
      <DetailsGrid data={model} columns={getColumns(t)} />
    </Section>
  );
}
