export const login = {
  greeting: 'أهلا بك في مجتمعنا',
  description: 'كل ما تحتاجه للإدارة والتحقق والدفع في مكان واحد',
  loginBy: 'قم بتسجيل الدخول باستخدام البريد الإلكتروني المسجل وكلمة المرور',
  forgetPassword: 'هل نسيت كلمة المرور؟',
  rememberMe: 'تذكرني',
  loginBtn: 'تسجيل دخول',
  loginPlaceholder: 'إدخل البريد الإلكتروني او اسم المستخدم',
  passwordPlaceholder: 'إدخل الرقم السري ',
};
