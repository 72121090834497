import { api } from 'lib/api';
import { TenantDetailsModel, TenantModel, ValidationType } from 'models/tenant.model';
import { PurchaseHistoryModel } from 'models/purchaseHistory.model';
import { BalanceHistoryModel } from 'models/balanceHistory.model';
import { PaginationModel } from 'models/paginationModel';

export type GetTenantsParams = {
  validation_type?: ValidationType;
};

const getTenants = (params: GetTenantsParams = {}) =>
  api.get<TenantModel[]>('/tenants', { params });

const getTenantDetails = (tenantId: number) =>
  api.get<TenantDetailsModel>(`/tenants/${tenantId}`).then(({ data, ...rest }) => {
    return {
      ...rest,
      data: {
        ...data,
        hourly_rate: +data.hourly_rate,
        total_paid_amount: +data.hourly_rate,
      },
    };
  });

const getTenantPurchaseHistory = (tenantId: number) =>
  api.get<PurchaseHistoryModel[]>(`/tenants/${tenantId}/purchase_history`);

type PurchaseHoursParams = {
  hours: number;
  paid_amount: number;
};

const purchaseHours = (tenantId: number, params: PurchaseHoursParams) =>
  api.patch<TenantDetailsModel>(`/tenants/${tenantId}/purchase_hours`, params);

type BalanceHistoryParams = {
  username?: string;
  type?: string;
  purchase_hours?: number;
  current_balance?: number;
  from?: string;
  to?: string;
  page?: number;
  limit?: number;
};

const balanceHistory = (tenantId: number, params: BalanceHistoryParams) =>
  api.get<{ items: BalanceHistoryModel[]; meta: PaginationModel }>(
    `/tenants/${tenantId}/balance_history`,
    { params },
  );

const sendBankTransferInvoice = (tenantId: number, files: FormData) =>
  api.post(`/tenants/${tenantId}/send-invoice`, files);

export const tenantsService = {
  getTenants,
  getTenantDetails,
  purchaseHours,
  getTenantPurchaseHistory,
  balanceHistory,
  sendBankTransferInvoice,
};
