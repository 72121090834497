import React, { useEffect, useState } from 'react';
import { TenantDetailsModel, ValidationType } from 'models/tenant.model';
import { Result } from 'antd';
import { tenantsService } from 'services/tenants.service';
import { PrePaid } from 'features/Purchase/components/Overview/components/PrePaid';
import { PostPaid } from 'features/Purchase/components/Overview/components/PostPaid';
import { RateModel } from 'models/rate.model';
import { PurchaseHistoryModel } from 'models/purchaseHistory.model';
import { useTranslation } from 'react-i18next';

interface Props {
  tenant: TenantDetailsModel;
  rate: RateModel;
}

export function ValidationTypeFactory({ tenant, rate }: Props) {
  const { t } = useTranslation();
  const [purchaseHours, setPurchaseHours] = useState<PurchaseHistoryModel[]>([]);

  function loadPurchaseHistory(tenantId: number) {
    tenantsService.getTenantPurchaseHistory(tenantId).then(({ data: purchaseHoursData }) => {
      setPurchaseHours(purchaseHoursData);
    });
  }

  useEffect(() => {
    loadPurchaseHistory(tenant.id);
  }, [tenant.id]);

  switch (tenant.validation_type) {
    case ValidationType.PrePaid:
      return <PrePaid tenant={tenant} rate={rate} purchaseHours={purchaseHours} />;
    case ValidationType.PostPaid:
      return <PostPaid tenant={tenant} rate={rate} purchaseHours={purchaseHours} />;
  }

  return <Result status="404" title="404" subTitle={t('page.notFoundText')} />;
}
