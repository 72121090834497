export enum ValidationType {
  PrePaid = 'prepaid',
  PostPaid = 'postpaid',
}

export interface TenantDetailsModel {
  id: number;
  tenant_name: string;
  hourly_rate: number;
  validation_limit: number;
  total_purchased_hours: number;
  total_paid_amount: number;
  total_validated_hours: number;
  facility_name: string;
  validation_type: ValidationType;
}

export interface TenantModel {
  tenant_id: number;
  facility_id: number;
  tenant_name: string;
}
