import React, { useCallback } from 'react';
import { UserForm, FormValues } from '../UserForm';
import { usersService } from 'services/users.service';
import { useNavigate } from 'react-router-dom';
import { useFacilities } from '../../hooks/useFacilities';
import { message } from 'antd';
import { getFormConfig } from './formConfig';
import { USERS_TENANT_USERS_OVERVIEW_URL } from 'utils/constants/clientUrls';
import { useTranslation } from 'react-i18next';

import './AddUser.scss';

export const AddUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: facilities } = useFacilities();

  const onFinish = useCallback((values: FormValues) => {
    usersService
      .createTenantUser({
        ...values,
        allow_entire_session_validation: !!values.allow_entire_session_validation,
        username: values.email,
        mobile: values.mobile_phone_code + values.mobile_phone,
      })
      .then(() => {
        message.success('The tenant user has been successfully created!');

        return navigate(USERS_TENANT_USERS_OVERVIEW_URL.route);
      })
      .catch((result) => {
        message.error(result.message);
      });
  }, []);

  return (
    <div className="tenant-users-add-user-content">
      <UserForm
        onFinish={onFinish}
        facilities={facilities as never}
        submitButtonText={t('tenantUsers.form.button')}
        formConfig={getFormConfig(t)}
      />
    </div>
  );
};
