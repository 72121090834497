import React from 'react';

export function CustomerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M12 15c-3.535 0-8 1.118-8 3.237 0 .243-.05 1.665 2.182 1.763h11.636C20.05 19.902 20 18.48 20 18.237 20 16.118 15.535 15 12 15Zm3.5-7c0 2.21-1.567 4-3.5 4s-3.5-1.79-3.5-4 1.567-4 3.5-4 3.5 1.79 3.5 4Z"
      />
    </svg>
  );
}
