import { TableProps } from 'antd/lib';
import { ReportModel } from 'models/report.model';
import moment from 'moment-timezone';
import { TFunction } from 'i18next';
import { datesDuration } from 'utils/date/datesDuration';

export function getColumns(t: TFunction): TableProps<ReportModel>['columns'] {
  return [
    {
      dataIndex: 'index',
      title: '#',
      width: '3%',
      render(value, entry, index) {
        return ('0' + (index + 1)).slice(-2);
      },
    },
    {
      dataIndex: 'date',
      title: t('report.table.date'),
      render(value) {
        try {
          return moment(value).local().format('YYYY-MM-DD HH:mm:ss');
        } catch (error) {
          return null;
        }
      },
    },
    {
      dataIndex: 'facility_name',
      title: t('report.table.facilityName'),
    },
    {
      dataIndex: 'tenant_name',
      title: t('report.table.tenantName'),
    },
    {
      dataIndex: 'tenant_username',
      title: t('report.table.userName'),
    },
    {
      dataIndex: 'plate_number',
      title: t('report.table.plateNumber'),
    },
    {
      dataIndex: 'customer_id',
      title: t('report.table.customerId'),
    },
    {
      dataIndex: 'mobile',
      title: t('report.table.customerMobile'),
    },
    {
      dataIndex: 'entire_session_duration',
      title: t('report.table.durationSession'),

      render(value, { date }) {
        return datesDuration(moment(), moment(date).local());
      },
    },
    {
      dataIndex: 'hours_validated',
      title: t('report.table.validatedHours'),
      render(value) {
        if (value === 0) {
          return t('validateSessions.table.entireSession');
        }

        return value;
      },
    },
  ];
}
