import { Collapse } from 'antd';
import { getItems } from 'components/Layout/SideBar/components/Menu/items';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { useTranslation } from 'react-i18next';

import './Menu.scss';

export const Menu = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const user = useAppSelector(getCurrentUser);
  const [activeKey, setActiveKey] = useState(location.pathname.split('/')[1]);

  const items = useMemo(() => {
    const panelStyle: React.CSSProperties = {
      background: 'unset',
      border: 'none',
    };

    return getItems(t, panelStyle, location.pathname)?.filter(({ access = null }) => {
      if (!access) {
        return true;
      }

      return access.includes(user?.account_type);
    });
  }, [user, location.pathname, t]);

  return (
    <Collapse
      onChange={(key) => setActiveKey(key[0])}
      accordion
      className="menu"
      activeKey={activeKey}
      bordered={false}
      style={{ background: 'unset' }}
      items={items}
    />
  );
};
