import React from 'react';
import { List, Modal } from 'antd';
import { Icon } from 'components/Icons';
import { ListItems } from './type';

import './SelectModal.scss';

type SelectModalProps<T> = {
  title: string;
  list: ListItems<T>;
  open: boolean;
  onCancel: () => void;
  onSelect: (key: T) => void;
};

export function SelectModal<T extends number | string>({
  title,
  list,
  open,
  onCancel,
  onSelect,
}: SelectModalProps<T>) {
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      className="select-modal"
      width="100%"
      footer={false}
    >
      <List>
        {list.map(({ key, name, icon }) => (
          <List.Item key={key} onClick={() => onSelect(key)}>
            <Icon component={icon} />
            {name}
          </List.Item>
        ))}
      </List>
    </Modal>
  );
}

export default SelectModal;
