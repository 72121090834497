import { facility, tenant } from './common';

export const bankTransfer = {
  form: {
    facility,
    tenant,
  },
  sections: {
    paidAmount: 'Paid Amount',
    hoursToBeAdded: 'Hours To Be Added',
    hours: 'Hours',
    add: 'Add',
  },
  modal: {
    hoursAddedSuccessfully: 'Hours Added Successfully',
    hoursAdded: '<b>{{value}} hours</b> has been successfully added to the user’s account.',
    invoiceSent: 'Invoice Sent',
    invoiceSentSuccessfully:
      'Your invoice has <b>successfully</b> been sent to our support email. Once approved, we will\n' +
      ' continue to credit your account with purchased hours',
  },
};
