import { Button } from 'antd';
import React from 'react';

import './IconButton.scss';
import classNames from 'classnames';

type Props = {
  icon: string;
  onClick?: () => void;
  className?: string;
};

export function IconButton({ icon, onClick, className }: Props) {
  return (
    <Button
      icon={<img src={icon} alt="Icon" />}
      onClick={onClick}
      className={classNames('icon-button', className)}
    />
  );
}
