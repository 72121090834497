import { UserModel } from 'models/user.model';
import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';

export function getColumns(t: TFunction): ColumnsType<UserModel> {
  return [
    {
      title: '#',
      width: '3%',
      render(value, entry, index) {
        return ('0' + (index + 1)).slice(-2);
      },
    },
    {
      dataIndex: 'email',
      title: t('tenantUsers.table.userEmail'),
    },
    {
      dataIndex: 'display_name',
      title: t('tenantUsers.table.userName'),
    },
    {
      dataIndex: 'facility',
      title: t('tenantUsers.table.facility'),
    },
    {
      dataIndex: 'tenant_name',
      title: t('tenantUsers.table.tenantName'),
    },
    {
      dataIndex: 'mobile',
      title: t('tenantUsers.table.mobileNumber'),
    },
  ];
}
