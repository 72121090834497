import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Typography } from 'antd';

import './Content.scss';

type ContentProps = PropsWithChildren<{ title?: React.ReactNode; className?: string }>;

export function Content({ children, title, className }: ContentProps) {
  return (
    <div className={classNames('content', className)}>
      {title && <Typography.Title className="content-title">{title}</Typography.Title>}
      {children}
    </div>
  );
}
