import React from 'react';
import icon from '../../assets/images/blank-user.svg';

import './UserIcon.scss';

export const UserIcon = () => {
  return (
    <div className="icon-container">
      <img src={icon} alt="user" />
    </div>
  );
};
