import { useEffect, useState } from 'react';
import { TenantModel } from 'models/tenant.model';
import { GetTenantsParams, tenantsService } from 'services/tenants.service';

export function useTenants(params: GetTenantsParams = {}) {
  const [tenants, setTenants] = useState<TenantModel[]>([]);

  useEffect(() => {
    tenantsService.getTenants(params).then(({ data }) => setTenants(data));
  }, []);

  return tenants;
}
