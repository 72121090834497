import { FormConfig } from '../UserForm';
import { getFormConfig as createFormConfig } from '../AddUser/formConfig';
import { confirmRule, passwordRule } from 'utils/form/validation';
import { TFunction } from 'i18next';

export function getFormConfig(t: TFunction): FormConfig {
  return {
    ...createFormConfig(t),
    password: {
      name: 'password',
      rules: [passwordRule(8, t)],
    },
    confirmPassword: {
      name: 'confirm_password',
      dependencies: ['password'],
      rules: [confirmRule(t('tenantUsers.validation.confirmPassword'))],
    },
  };
}
