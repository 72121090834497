import { jsPDF } from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { ColumnType } from 'antd/lib/table/interface';

export function generateTableReport<T>(
  columns: ColumnType<T>[],
  data: T[],
  filename: string = 'Report',
) {
  const doc = new jsPDF({
    orientation: 'l',
  });

  autoTable(doc, {
    head: [columns.map((c) => c.title)] as RowInput[],
    body: data.map(
      (r, index) =>
        columns.map((c) => {
          const value = r[c.dataIndex as keyof T];

          return c.render ? c.render(value, r, index) : value;
        }) as RowInput,
    ),
  });

  doc.save(`${filename}.pdf`);
}
