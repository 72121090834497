import {
  date,
  facilityName,
  tenantName,
  user,
  hours,
  purchaseHours,
  facility,
  tenant,
  search,
} from './common';
import { ValidationType } from 'models/tenant.model';

export const purchase = {
  tenantDetails: 'Tenant Details',
  facilityName,
  tenantName,
  validationType: 'Validation Type',
  prePaidInformation: 'Pre-paid Information',
  availableHours: 'Available Hours',
  totalBoughtHours: 'Total Bought Hours',
  totalConsumedHours: 'Total Consumed Hours',
  postPaidInformation: 'Post-Paid Information',
  validationLimit: 'Validation Limit',
  currentConsumedHours: 'Current Consumed Hours',
  dueAmount: 'DUE AMOUNT',
  hours,
  payNow: 'PAY NOW',
  tenantPurchaseHistory: 'Tenant Purchase History',
  date,
  user,
  totalHours: 'Total Hours',
  paidAmount: 'Paid amount',
  validationTypeLabels: {
    [ValidationType.PrePaid]: 'Pre-Paid',
    [ValidationType.PostPaid]: 'Post-Paid',
  },
  hoursToBePurchased: 'Hours To Be Purchased',
  totalAmount: 'Total Amount',
  purchaseHours,
  purchaseHoursNote:
    'Each hour is multiplied by {{rate}}. Example: {{amount}} (Hour) x {{rate}} (Hourly-rate) = {{result}} Riyal',
  message: {
    successPayment: 'The payment was successful!',
  },
  form: {
    facility,
    tenant,
    search,
  },
  modal: {
    selectPaymentMethod: 'Select Type Of Payment Method',
  },
};
