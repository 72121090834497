import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usersService } from 'services/users.service';
import { UserDetailsModel } from 'models/userDetails.model';
import { Flex, message, Result, Spin } from 'antd';
import { FormValues, UserForm } from '../UserForm';
import { useFacilities } from '../../hooks/useFacilities';
import { getFormConfig } from './formConfig';
import { USERS_TENANT_USERS_OVERVIEW_URL } from 'utils/constants/clientUrls';
import { useTranslation } from 'react-i18next';

function getFormValues(user: UserDetailsModel): FormValues {
  const result = new RegExp(/^(00|0|\+)?(966|971|965|968|973|974|5|)(5[0-9]{8})$/).exec(
    user.mobile,
  );

  const mobilePhoneCode = result?.[2] || '';
  const mobilePhone = result?.[3] || '';

  return {
    password: '',
    password_confirm: '',
    mobile_phone_code: mobilePhoneCode,
    mobile_phone: mobilePhone,
    ...user,
  };
}

export function EditUser() {
  const { t } = useTranslation();
  const { userId } = useParams();
  const { data: facilities } = useFacilities();
  const [user, setUser] = useState<UserDetailsModel>();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      return;
    }

    usersService
      .getTenantUserDetails(+userId)
      .then(({ data }) => setUser(data))
      .finally(() => setLoaded(true));
  }, [userId]);

  const onFinish = useCallback(
    (values: FormValues) => {
      usersService
        .editTenantUser(Number(userId), {
          ...values,
          allow_entire_session_validation: !!values.allow_entire_session_validation,
          username: values.email,
          mobile: values.mobile_phone_code + values.mobile_phone,
        })
        .then(() => {
          message.success(t('tenantUsers.message.updated'));

          return navigate(USERS_TENANT_USERS_OVERVIEW_URL.route);
        })
        .catch((result) => {
          message.error(result.message);
        });
    },
    [t],
  );

  if (!loaded) {
    return (
      <Flex gap="small" vertical>
        <Spin />
      </Flex>
    );
  }

  if (!user) {
    return <Result status="404" title="404" subTitle={t('page.notFoundText')} />;
  }

  return (
    <div className="tenant-users-add-user-content">
      <UserForm
        onFinish={onFinish}
        values={getFormValues(user)}
        facilities={facilities}
        submitButtonText={t('tenantUsers.form.updateAccount')}
        formConfig={getFormConfig(t)}
      />
    </div>
  );
}
