import React, { PropsWithChildren } from 'react';
import { Content } from 'components/Content';

import './Layout.scss';

type LayoutProps = PropsWithChildren<{
  title?: string;
}>;

export function Layout({ title, children }: LayoutProps) {
  return (
    <div className="layout">
      <div className="checkout-layout">
        <header className="header">
          <h1>{title}</h1>
        </header>
        <div className="layout-content">
          <Content className="checkout">{children}</Content>
        </div>
      </div>
    </div>
  );
}
