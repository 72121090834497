import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
// translates
import en from './en';
import ar from './ar';
import { LanguageEnum } from 'utils/enums/language';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: LanguageEnum.English,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      [LanguageEnum.English]: en,
      [LanguageEnum.Arabic]: ar,
    },
  });

export default i18n;
