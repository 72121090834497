import { useFacilities, useTenants } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { TenantModel, ValidationType } from 'models/tenant.model';
import { useForm } from 'antd/es/form/Form';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { FacilityModel } from 'models/facility.model';

export function useSearchFormData<T>(): [
  FormInstance<T>,
  FacilityModel[],
  TenantModel[],
  (value: number) => void,
] {
  const originalTenants = useTenants({ validation_type: ValidationType.PrePaid });
  const originalFacilities = useFacilities();

  const [form] = useForm<T>();
  const [tenants, setTenants] = useState<TenantModel[]>([]);
  const [facilities, setFacilities] = useState<FacilityModel[]>([]);

  useEffect(() => {
    const facilitiesId = originalTenants
      .map(({ facility_id }) => facility_id)
      .filter((v, i, self) => i == self.indexOf(v));

    setFacilities(
      originalFacilities.filter(({ facility_id }) => facilitiesId.includes(facility_id)),
    );
  }, [originalTenants, originalFacilities]);

  const onChangeFacilityIdHandler = useCallback(
    (value: number) => {
      form.resetFields(['tenant_id']);
      setTenants(originalTenants.filter(({ facility_id }) => facility_id === value));
    },
    [originalTenants],
  );

  return [form, facilities, tenants, onChangeFacilityIdHandler];
}
