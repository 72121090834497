import React, { useCallback, useEffect, useState } from 'react';
import { Content } from 'components/Content';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCurrentUser } from 'store/user/selectors';
import { TenantModel } from 'models/tenant.model';
import { tenantsService } from 'services/tenants.service';
import { Divider } from 'antd';
import { BalanceHistorySearchForm } from './components/BalanceHistorySearchForm';
import { BalanceHistoryTable } from './components/BalanceHistoryTable';
import { FacilityModel } from 'models/facility.model';
import { hasRole } from 'utils/permission/role';
import { AccountType } from 'models/user.model';
import { facilitiesService } from 'services/facility.service';
import { BalanceHistoryModel } from 'models/balanceHistory.model';
import { defaultPagination, PaginationModel } from 'models/paginationModel';

import './BalanceHistory.scss';

type SearchParams = {
  tenant_id?: number;
  username?: string;
  type?: string;
  purchase_hours?: number;
  current_balance?: number;
  date_from?: string;
  date_to?: string;
};

export function BalanceHistory() {
  const user = useAppSelector(getCurrentUser);
  const [loaded, setLoaded] = useState(true);
  const [facilities, setFacilities] = useState<FacilityModel[]>();
  const [tenants, setTenants] = useState<TenantModel[]>();
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [tableData, setTableData] = useState<{
    data?: BalanceHistoryModel[];
    pagination: PaginationModel;
  }>({
    pagination: defaultPagination,
  });

  const loadData = useCallback((tenantId: number, params = {}) => {
    setLoaded(false);

    tenantsService
      .balanceHistory(tenantId, params)
      .then(({ data: { items, meta } }) => {
        setTableData({
          data: items,
          pagination: meta,
        });
      })
      .finally(() => {
        setLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (hasRole(user?.account_type, [AccountType.TenantAdmin])) {
      loadData(user.tenant_id);
    }

    if (hasRole(user?.account_type, [AccountType.Admin, AccountType.FacilityOwner])) {
      facilitiesService.getFacilities().then(({ data }) => setFacilities(data));
      tenantsService.getTenants().then(({ data }) => setTenants(data));
    }
  }, [user?.tenant_id]);

  const onFormSubmitHandler = ({
    facility_id,
    tenant_id,
    ...params
  }: {
    facility_id?: number;
    tenant_id?: number;
  }) => {
    loadData(tenant_id ? tenant_id : user?.tenant_id, params);
    setSearchParams({ tenant_id, ...params });
  };

  const onChangePaginationHandler = useCallback(
    (page: number) => {
      const { tenant_id = undefined, ...params } = searchParams;

      loadData(tenant_id ? tenant_id : user?.tenant_id, {
        ...params,
        page,
      });
    },
    [searchParams],
  );

  return (
    <Content className="purchase-balance-history">
      <BalanceHistorySearchForm
        onFormSubmit={onFormSubmitHandler}
        facilities={facilities}
        tenants={tenants}
      />
      {Array.isArray(tableData.data) && (
        <>
          <Divider />
          <BalanceHistoryTable
            loading={!loaded}
            data={tableData}
            onChangePagination={onChangePaginationHandler}
          />
        </>
      )}
    </Content>
  );
}
