import { ReportModel } from 'models/report.model';
import reportsService from 'services/reports.service';

const ITEMS_LIMIT = 100;

export async function loadReportsData(searchParams: object) {
  let page = 0;
  const result: ReportModel[] = [];

  while (true) {
    const params = { ...searchParams, page: ++page, limit: ITEMS_LIMIT };
    const {
      data: { items, meta },
    } = await reportsService.getReports(params);

    result.push(...items);

    if (page >= meta.totalPages) {
      break;
    }
  }

  return result;
}
