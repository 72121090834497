import axios from 'axios';
import { authService } from 'services/auth.service';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const api = axios.create({ baseURL: BASE_URL });

api.interceptors.request.use((config) => {
  const token = authService.getAccessToken();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers!.Authorization = `Bearer ${token}`;
  }

  return config;
});
