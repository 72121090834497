import React from 'react';
import { Row as AntdRow, Col } from 'antd';

import './Row.scss';

interface Props {
  data: string[];
}

export function Row({ data }: Props) {
  const span = 24 / (data.length || 1);

  return (
    <AntdRow className="post-paid-information-row">
      {data.map((value) => (
        <Col key={value} span={span} className="post-paid-information-col">
          {value}
        </Col>
      ))}
    </AntdRow>
  );
}
