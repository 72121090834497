import { useCallback, useState } from 'react';

export function useModal(): [boolean, () => void, () => void] {
  const [open, setOpen] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  return [open, openModal, closeModal];
}
