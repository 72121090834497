import { Columns } from 'components/DetailsGrid';
import { TenantDetailsModel } from 'models/tenant.model';
import { TFunction } from 'i18next';

export function getColumns(t: TFunction): Columns<TenantDetailsModel>[] {
  return [
    {
      index: 'validation_limit',
      title: t('purchase.validationLimit'),
      render(value) {
        return t('purchase.hours', { value: value });
      },
    },
    {
      index: 'total_validated_hours',
      title: t('purchase.currentConsumedHours'),
      render(value, _, record: TenantDetailsModel) {
        const result = +record.total_validated_hours - +record.total_purchased_hours;

        return t('purchase.hours', {
          value: result > 0 ? result : 0,
        });
      },
    },
    {
      index: 'total_consumed_hours',
      title: t('purchase.availableHours'),
      render(value, _, record) {
        return t('purchase.hours', {
          value:
            +record.validation_limit -
            (+record.total_validated_hours - +record.total_purchased_hours),
        });
      },
    },
  ];
}
