import React from 'react';

const BankTransferIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 35">
      <g clipPath="url(#bank-transfer)">
        <path
          fill="currentColor"
          d="M29.258 2.803c-.514-.643-.514-1.682 0-2.318.514-.636 1.345-.642 1.854 0l3.5 4.375c.246.308.383.725.383 1.162 0 .438-.137.855-.383 1.162l-3.5 4.375c-.514.643-1.346.643-1.854 0-.509-.642-.514-1.681 0-2.317l1.258-1.572L21 7.656c-.727 0-1.313-.731-1.313-1.64 0-.91.586-1.641 1.313-1.641h9.521l-1.263-1.572ZM5.742 25.77l-1.258 1.573H14c.727 0 1.313.731 1.313 1.64 0 .91-.586 1.64-1.313 1.64H4.479l1.258 1.573c.514.643.514 1.682 0 2.317-.514.636-1.346.643-1.854 0l-3.5-4.368A1.869 1.869 0 0 1 0 28.984c0-.437.137-.854.383-1.162l3.5-4.375c.514-.642 1.345-.642 1.854 0 .508.643.514 1.682 0 2.318l.005.006ZM5.25 4.375h13.229a4.307 4.307 0 0 0-.323 1.64c0 1.962 1.274 3.555 2.844 3.555h6.42c-.218 1.162.033 2.427.755 3.336 1.11 1.388 2.91 1.388 4.02 0l1.055-1.32V26.25c0 2.413-1.57 4.375-3.5 4.375H16.521a4.307 4.307 0 0 0 .323-1.64c0-1.963-1.275-3.555-2.844-3.555H7.58c.218-1.163-.033-2.427-.755-3.336-1.11-1.388-2.91-1.388-4.02 0L1.75 23.413V8.75c0-2.413 1.57-4.375 3.5-4.375Zm3.5 4.375h-3.5v4.375c1.93 0 3.5-1.962 3.5-4.375Zm21 13.125c-1.93 0-3.5 1.962-3.5 4.375h3.5v-4.375ZM17.5 24.062c1.392 0 2.728-.691 3.712-1.922.985-1.23 1.538-2.9 1.538-4.64 0-1.74-.553-3.41-1.538-4.64-.984-1.231-2.32-1.923-3.712-1.923-1.392 0-2.728.692-3.712 1.923-.985 1.23-1.538 2.9-1.538 4.64 0 1.74.553 3.41 1.538 4.64.984 1.231 2.32 1.922 3.712 1.922Z"
        />
      </g>
      <defs>
        <clipPath id="bank-transfer">
          <path fill="#fff" d="M0 0h35v35H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BankTransferIcon;
