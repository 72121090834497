import React from 'react';

export function ExcelFile() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M21.26 12.819h-3.817V10.91h3.817v1.909Zm0 1.09h-3.817v1.909h3.817v-1.909Zm0-8.997h-3.817v1.909h3.817V4.912Zm0 3h-3.817v1.907h3.817V7.912Zm0 8.996h-3.817v1.909h3.817v-1.909Zm2.636 3.762c-.109.567-.79.581-1.246.6h-8.478v2.454h-1.694L0 21.543V2.46L12.552.277h1.62V2.45h8.187c.46.02.968-.013 1.368.262.281.403.254.916.273 1.38l-.01 14.193c-.014.793.073 1.603-.094 2.385ZM9.997 16.393c-.752-1.527-1.518-3.043-2.268-4.57a565.188 565.188 0 0 0 2.2-4.468c-.619.03-1.237.068-1.854.112-.46 1.12-.997 2.21-1.357 3.37-.336-1.094-.78-2.146-1.186-3.212-.6.033-1.2.068-1.8.103.633 1.396 1.306 2.773 1.92 4.177-.723 1.363-1.399 2.746-2.1 4.117.597.025 1.195.049 1.792.057.425-1.085.954-2.13 1.325-3.236.332 1.189.897 2.287 1.36 3.424.657.047 1.311.088 1.968.126ZM22.7 3.745h-8.528v1.167h2.18v1.909h-2.18v1.09h2.18V9.82h-2.18v1.09h2.18v1.909h-2.18v1.09h2.18v1.909h-2.18v1.09h2.18v1.909h-2.18v1.26H22.7V3.745Z"
      />
    </svg>
  );
}
