import React from 'react';
import AntIcon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type IconProps = {
  component: IconComponentProps['component'];
};

import './Icon.scss';

export function Icon({ component }: IconProps) {
  return <AntIcon component={component} className="icon" />;
}
