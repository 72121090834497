import React from 'react';
import { CheckoutCard } from 'features/Purchase/components/Checkout/components/CheckoutCard';
import { TenantDetailsModel } from 'models/tenant.model';
import { RateModel } from 'models/rate.model';
import { useTranslation } from 'react-i18next';
import { Button, message, Modal, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';

import './BankTransfer.scss';
import { tenantsService } from 'services/tenants.service';
import { useModal } from 'components/SelectModal/hooks/useModal';

type BankTransferProps = {
  tenant: TenantDetailsModel;
  rate: RateModel;
  hours: number;
  tax?: number;
};

export function BankTransfer({ tenant, rate, hours, tax }: BankTransferProps) {
  const { t } = useTranslation();
  const [open, openModal, closeModal] = useModal();

  const onChangeHandler = (file: RcFile) => {
    const formData = new FormData();
    formData.append('files', file);

    tenantsService
      .sendBankTransferInvoice(tenant.id, formData)
      .then(() => openModal())
      .catch((response) => {
        message.error(response?.response?.data?.message || response.message);
      });

    return false;
  };

  return (
    <CheckoutCard
      tenant={tenant}
      paymentTitle={t('cardPayment.bankAccountInformation')}
      rate={rate}
      hours={hours}
      tax={tax}
      footer={
        <div className="payment-summary-footer">
          <p>{t('cardPayment.uploadPaymentInvoice')}:</p>
          <Upload
            beforeUpload={onChangeHandler}
            showUploadList={false}
            accept=".doc,.docx,application/pdf,,image/*"
          >
            <Button>{t('cardPayment.selectFiles')}</Button>
          </Upload>
        </div>
      }
    >
      <ul className="bank-account-information">
        <li>
          <b>{t('cardPayment.bankName')}:</b> <span>ANB بنك العربي</span>
        </li>
        <li>
          <b>{t('cardPayment.companyName')}:</b> <span>WOQOOF CO LTD</span>
        </li>
        <li>
          <b>{t('cardPayment.accountNumber')}:</b> <span>0108095460550012</span>
        </li>
        <li>
          <b>{t('cardPayment.iban')}:</b> <span>SA1930400108095460550012</span>
        </li>
      </ul>
      <Modal
        destroyOnClose
        onCancel={closeModal}
        open={open}
        footer={false}
        title={t('bankTransfer.modal.invoiceSent')}
        rootClassName="bank-transfer-modal"
      >
        <span
          dangerouslySetInnerHTML={{ __html: t('bankTransfer.modal.invoiceSentSuccessfully') }}
        />
      </Modal>
    </CheckoutCard>
  );
}
