import React from 'react';

export function TypeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 24">
      <g clipPath="url(#type-id)">
        <path
          fill="currentColor"
          d="M18.333 2c.487 0 .953.21 1.297.586.344.375.537.884.537 1.414v12c0 .53-.194 1.04-.537 1.414a1.76 1.76 0 0 1-1.297.586h-2.75v2c0 .53-.193 1.04-.537 1.414A1.76 1.76 0 0 1 13.75 22H3.667a1.76 1.76 0 0 1-1.297-.586A2.096 2.096 0 0 1 1.833 20V9c0-.53.193-1.04.537-1.414A1.76 1.76 0 0 1 3.667 7H5.5V4c0-.53.193-1.04.537-1.414A1.76 1.76 0 0 1 7.333 2h11ZM5.5 9H3.667v11H13.75v-2H7.333a1.76 1.76 0 0 1-1.296-.586A2.096 2.096 0 0 1 5.5 16V9Zm12.833-5h-11v12h11V4ZM16.4 7.233c.172.188.269.442.269.707 0 .265-.097.52-.269.707l-3.825 4.172a1.007 1.007 0 0 1-.327.239.936.936 0 0 1-.772 0 1.007 1.007 0 0 1-.327-.239l-1.88-2.05A1.048 1.048 0 0 1 9 10.062c0-.265.096-.52.268-.707a.88.88 0 0 1 .648-.294.88.88 0 0 1 .649.293l1.296 1.414 3.241-3.535a.88.88 0 0 1 .648-.293c.243 0 .477.106.65.293Z"
        />
      </g>
      <defs>
        <clipPath id="type-id">
          <path fill="#fff" d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
