import React, { PropsWithChildren, ReactNode } from 'react';
import { Col, List, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { RateModel } from 'models/rate.model';
import { TenantDetailsModel, ValidationType } from 'models/tenant.model';

import './CheckoutCard.scss';

type CheckoutCardProps = PropsWithChildren<{
  tenant: TenantDetailsModel;
  paymentTitle: ReactNode;
  rate: RateModel;
  hours: number;
  tax?: number;
  footer: ReactNode;
}>;

export function CheckoutCard({
  tenant,
  paymentTitle,
  hours,
  rate,
  children,
  footer,
  tax = 15,
}: CheckoutCardProps) {
  const { t } = useTranslation();

  const subtotal = Number(hours * rate.rate);
  const vat = Number(subtotal * (tax / 100)).toFixed(2);

  return (
    <Row className="checkout-card">
      <Col xs={24} md={12} className="checkout-card-section">
        <h4 className="checkout-card-section-title">{paymentTitle}</h4>
        {children}
      </Col>
      <Col xs={24} md={12} className="checkout-card-section checkout-card-section--accent">
        <h4 className="checkout-card-section-title">{t('cardPayment.paymentSummary')}</h4>
        <List>
          {tenant.validation_type === ValidationType.PrePaid && (
            <List.Item>
              <span>{t('cardPayment.hoursToBeBought')}:</span>
              <span>{t('cardPayment.hours', { value: hours })}</span>
            </List.Item>
          )}
          {tenant.validation_type === ValidationType.PostPaid && (
            <List.Item>
              <span>{t('cardPayment.currentConsumedHours')}:</span>
              <span>{t('cardPayment.hours', { value: hours })}</span>
            </List.Item>
          )}
          <List.Item>
            <span>{t('cardPayment.subtotal')}:</span>
            <span>
              {subtotal} {t('currency.sar')}
            </span>
          </List.Item>
          <List.Item>
            <span>{t('cardPayment.vat', { value: tax })}:</span>
            <span>
              {vat} {t('currency.sar')}
            </span>
          </List.Item>
          <List.Item>
            <span>{t('cardPayment.total')}: </span>
            <span>
              {subtotal + +vat} {t('currency.sar')}
            </span>
          </List.Item>
        </List>
        {footer}
      </Col>
    </Row>
  );
}
