import React, { PropsWithChildren } from 'react';
import { Divider, Typography } from 'antd';

import './Section.scss';

const { Title } = Typography;

type SectionProps = PropsWithChildren<{
  title?: string;
  showDivider?: boolean;
}>;

export function Section({ title, showDivider, children }: SectionProps) {
  return (
    <div className="form-section">
      {title && (
        <Title level={4} className="form-section-title">
          {title}
        </Title>
      )}
      {children}
      {showDivider && <Divider />}
    </div>
  );
}
