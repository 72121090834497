import React from 'react';
import { PaginationProps } from 'antd';
import { PaginationModel } from 'models/paginationModel';
import { TFunction } from 'i18next';

export const getItemRender =
  (t: TFunction, pagination: PaginationModel): PaginationProps['itemRender'] =>
  (page, type, originalElement) => {
    if (page < 1 && type === 'prev') {
      return;
    }

    if (page === pagination.totalPages && type === 'next') {
      return;
    }

    if (['prev', 'next'].includes(type) && pagination.itemCount <= 1) {
      return;
    }

    if (type === 'prev') {
      return <span>{t('table.prev')}</span>;
    }

    if (type === 'next') {
      return <span>{t('table.next')}</span>;
    }

    return originalElement;
  };
