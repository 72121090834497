import { SelectValidationDuration } from './components/SelectValidationDuration';
import { ConfirmationMessage } from './components/ConfirmationMessage';
import { TFunction } from 'i18next';

export function getStepItems(t: TFunction) {
  return [
    {
      title: t('validateSessions.modal.selectTheValidationDuration'),
      content: SelectValidationDuration,
    },
    {
      title: t('validateSessions.modal.confirmationMessage'),
      content: ConfirmationMessage,
    },
  ];
}
