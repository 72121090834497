import React, { useState } from 'react';
import { Divider, Form, InputNumber, message, Modal, Space } from 'antd';
import { TenantDetailsModel } from 'models/tenant.model';
import { FullWidthButton } from 'components/Button/FullWidthButton';
import { tenantsService } from 'services/tenants.service';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/SelectModal/hooks/useModal';

import './BankTransferPurchaseForm.scss';

type BankTransferPurchaseFormProps = {
  tenant: TenantDetailsModel;
};

type FormValues = {
  hours: number;
  paid_amount: number;
};

export function BankTransferPurchaseForm({ tenant }: BankTransferPurchaseFormProps) {
  const { t } = useTranslation();
  const [open, openModal, closeModal] = useModal();
  const [hours, setHours] = useState<number>(0);

  const onFinishHandler = (values: FormValues) => {
    tenantsService
      .purchaseHours(tenant.id, values)
      .then(() => {
        setHours(values.hours);

        openModal();
      })
      .catch((response) => {
        message.error(response?.response?.data?.message || response.message);
      });
  };

  return (
    <Form onFinish={onFinishHandler} className="bank-transfer-purchase-form">
      <Divider />
      <div className="section">
        <h4 className="section-title">{t('bankTransfer.sections.paidAmount')}</h4>
        <Space className="space">
          <div className="space-title">{t('bankTransfer.sections.paidAmount')}</div>
          <div className="wrapper">
            <Form.Item
              name="paid_amount"
              rules={[{ required: true, message: t('validation.required') }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <span className="section-currency-code">{t('currency.sar')}</span>
          </div>
        </Space>
      </div>
      <div className="section">
        <h4 className="section-title">{t('bankTransfer.sections.hoursToBeAdded')}</h4>
        <Space className="space">
          <div className="space-title">{t('bankTransfer.sections.hours')}</div>
          <div className="space-item">
            <Form.Item name="hours" rules={[{ required: true, message: t('validation.required') }]}>
              <InputNumber min={1} onKeyDown={(e) => ['.'].includes(e.key) && e.preventDefault()} />
            </Form.Item>
          </div>
        </Space>
      </div>

      <FullWidthButton htmlType="submit" type="primary">
        {t('bankTransfer.sections.add')}
      </FullWidthButton>
      <Modal
        destroyOnClose
        onCancel={closeModal}
        open={open}
        footer={false}
        title={t('bankTransfer.modal.hoursAddedSuccessfully')}
        rootClassName="bank-transfer-modal"
      >
        <span
          dangerouslySetInnerHTML={{ __html: t('bankTransfer.modal.hoursAdded', { value: hours }) }}
        />
      </Modal>
    </Form>
  );
}
