import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import './Note.scss';

type NoteProps = PropsWithChildren<{ className?: string }>;

export function Note({ children, className }: NoteProps) {
  return <div className={classNames('note', className)}>{children}</div>;
}
